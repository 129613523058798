.not-found-container {
    height: calc(100vh - 208px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found-content  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .not-found-content h2 {
        font-size: 24px;
    }

    .not-found-content img {
        height: 250px;
    }
}

@media (max-width: 486px) {
    .not-found-content h2 {
        white-space: normal;
    }
}

.not-found-content p {
    text-align: center;
    color: #6c757d;
}

