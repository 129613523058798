.main_navbar_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-left: 1rem;
    min-height: 50px;
}

.main_navbar_right {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 2rem;
}

.main_navbar_left {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2rem;
    position: relative;
}

.main_navbar_left::before,
.main_navbar_right::before {
    content: '';
    height: 1.8rem;
    width: 1px;
    color: #FFFFFF;
    background-color: rgba(236, 236, 236, .2);
}

.navbar_menu_item.main_item {
    position: relative;
}

.navbar_menu_item.main_item::after {
    content: '';
    display: block;
    background-color: #FFFFFF;
    position: absolute;
    right: 0;
    left: 0;
    width: 25%;
    height: 0.125rem;
    opacity: 0;
    transition: width 0.3s,opacity 0.1s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.15, 1);
}

.navbar_menu_item.main_item:hover::after,
.navbar_menu_item.main_item.active::after {
    width: 100%;
    opacity: 1;
}

.navbar_menu_item {
    cursor: pointer;
    font-size: 13px;
}

.navbar_menu_item a:hover {
    text-decoration: none;
}

.impaired_menu_item img {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
}

.cart_menu_item:hover .cart-sub {
    display: block;
    line-height: normal;
}

.cart_menu_item {
    position: relative;
    height: 100%;
    line-height: 50px;
    padding-right: 1rem;
}

.cart_menu_title {
    padding-left: 11px;
    text-decoration: none;
}

.cart_menu_item i {
    font-size: 1.3rem;
}

.cart_menu_link {
    position: relative;
}

.cart-item-counter {
    position: absolute;
    color: #fff;
    background: #ef2301;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    bottom: 5px;
    left: 12px;
}

.cart-item-counter #cart_count {
    padding: 2px;
    font-weight: 600;
    font-size: 1.1rem;
}

.profile_icon {
    font-size: 2rem;
}

.profile_name {
    display: none;
}

.profile_name_dropdown {
    display: block;
    padding: 3px 20px;
    font-weight: 600;
}

.main-toggle-title {
    color: #FFFFFF;
    cursor: pointer;
}

.main_extended_items {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2rem;
    padding: 1rem 0;
    border-top: 1px solid #ECECEC33;
}

.main_extended_items .main_item {
    display: none;
}

.extended-main-menu {
    display: none;
}

#main-extended_nav.collapse.in{
    display: none;
}

.sub-menu-more-toggle .icon-bar:hover {
    border: none;
    opacity: 1;
}

@media (max-width: 1250px) {
    .main_navbar_left > .main_item:nth-child(n + 8) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 8) {
        display: block;
    }

    .extended-main-menu {
        display: block;
    }

    #main-extended_nav.collapse.in{
        display: block;
    }
}

@media (max-width: 1200px) {
    .main_navbar_left > .main_item:nth-child(n + 7) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 7) {
        display: block;
    }
}

@media (max-width: 1150px) {
    .main_navbar_left > .main_item:nth-child(n + 6) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 6) {
        display: block;
    }
}

@media (max-width: 1060px) {
    .main_navbar_left > .main_item:nth-child(n + 5) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 5) {
        display: block;
    }
}

@media (max-width: 980px) {
    .main_navbar_left > .main_item:nth-child(n + 4) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 4) {
        display: block;
    }
}

@media (max-width: 850px) {
    .main_navbar_left > .main_item:nth-child(n + 3) {
        display: none;
    }

    .main_extended_items > .main_item:nth-child(n + 3) {
        display: block;
    }
}

@media (max-width: 767px) {
    .profile_dropdown {
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        float: none;
        margin-top: 0;
        position: static;
        width: 100%;
    }

    .main_navbar_items {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .profile_menu_item {
        width: 100%;
    }

    .cart_menu_item:hover .cart-sub {
        display: none;
    }

    .profile_name {
        display: inline-block;
    }

    .profile_name_dropdown {
        display: none;
    }

    #main-extended_nav.collapse.in{
        display: none;
    }

    .main_navbar_left,
    .main_navbar_right {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .main_navbar_left::before {
        display: none;
    }

    .main_navbar_right::before {
        height: 1px;
        width: 100%;
    }

    .main_navbar_left > .main_item {
        display: block !important;
    }

    .extended-main-menu {
        display: none;
    }

    .dropdown-backdrop {
        z-index: -1;
    }
}
