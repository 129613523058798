.category_list_header,
.category_list_row {
  display: grid;
  grid-template-columns: 30% 50% 20%;
  align-items: center;
  padding: 1rem;
}

.attribute_list_header,
.attribute_list_row {
  display: grid;
  grid-template-columns: 20% 30% 30% 20%;
  align-items: center;
  padding: 1rem;
}

.product_group_list_header,
.product_group_list_row {
  display: grid;
  grid-template-columns: 20% 30% 30% 20%;
  align-items: center;
  padding: 1rem;
}

.table_list_item:last-child {
  margin-left: auto;
}

.category_list_row,
.attribute_list_row,
.product_group_list_row {
  background-color: #fff;
  margin: 1rem 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.list_action_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.list_action_btn {
  cursor: pointer;
  font-size: 2rem;
}

.hidden_product {
  background: rgba(0,0,0,.1);
}

.hidden_product_group {
  opacity: 0.3;
}

/* Product Settings Category */

.category_btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.category_btn {
  padding: 0.9rem;
  background-color: #415a77;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
}

.category_btn:hover {
  background-color: #778da9;
}

.category_btn.active {
  background-color: #0d1b2a;
}

/* Settings Product table */
.product_list {
  overflow-x: auto;
}

.product_table {
  width: 100%;
  margin-bottom: 5rem;
  overflow-x: auto;
}

.product_table .limit_text {
  position: relative;
}

.product_table .recommended_icon {
  position: absolute;
  top: 0;
  left: 0;
}

.product_table .recommended_icon i {
  color: #d89400;
}

.product_table tr,
.product_table td {
  padding-bottom: 20px;
}

.product_table td,
.product_table th {
  padding: 10px;
}

.table_action_btn {
  cursor: pointer;
}

.table_action_btns .dropdown-menu .dropdown-item a {
  padding: 0;
}

.table_action_btns .action_btn_item {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
}

.table_action_btns .dropdown-menu .dropdown-item {
  padding: 0.5rem;
  cursor: pointer;
}

.table_action_btns .dropdown-menu .dropdown-item:hover {
  background-color: #eee;
}

/* New product page */

.product_container {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  background-color: #fff;
  padding: 3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.product_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sprite_container {
  width: 100%;
  max-height: 500px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.loader_backdrop {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 5px;
  z-index: 120;
  display: none;
}

.loader_backdrop.loading {
  display: block;
}

.img-360-container {
  border: none;
  padding: 0;
  position: relative;
}

.img-360-container.loaded {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2rem 0;
}

.img-360-container.loaded .sprite_container,
.img-360-container.loaded .control-btns {
  display: flex;
}

.sprite_container img {
  display: block;
  position: relative;
  z-index: 1;
}

.img-360-container .control-btns {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.img-360-container .control-btns .rotate_icon {
  transform: rotateZ(180deg);
}

.img-360-container .control-btns input {
  width: 50%;
}

.img-360-container .product_sprite {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 1900%;
  background-size: 100% !important;
  opacity: 0;
}

.img-360-container.loaded .product_img {
  opacity: 0;
  display: none;
}

.img-360-container.loaded .product_sprite {
  cursor: grabbing;
  opacity: 1;
}

.product_img.default {
  padding: 8rem;
  font-size: 30rem;
}

.info_divider {
  border-bottom: 1px solid rgb(180, 180, 180);
}

.attribute_block input {
  margin: 5px 0;
}

.delete_btn {
  margin-top: 35px;
  cursor: pointer;
}

.product_item {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
  position: relative;
}

.product_item input,
.product_item textarea,
.product_item select {
  background-color: #eee;
}

/* Shop page */

.filter_wrapper {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 !important;
  display: none;
  overflow-y: auto;
}

.filter_wrapper.open {
  display: block;
}

.webshop_nav {
  transition: background-color 0.3s ease-in-out;
}

.webshop_nav:hover {
  background-color: #a3b3c0;
}

.group_buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.group_buttons .group_btn {
  cursor: pointer;
  color: #415a77;
  transition: all 0.2s ease-in-out;
}

.group_buttons .group_btn i {
  font-size: 3rem;
}

.group_buttons .group_btn:hover {
  color: #0d1b2a;
}

.group_buttons .group_btn.active {
  color: #0d1b2a;
}

.product_list_container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  gap: 60px 20px;
  margin: 20px 0 60px 0;
}

.recommended_list_title h1,
.shop_list_title h1 {
  color: #415a77;
  font-weight: 500;
}

.product_card {
  position: relative;
  width: 260px;
  height: 420px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  cursor: pointer;
}

.stacked_card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.stacked_card.first {
  width: 95%;
  background-color: #eeeeee;
  top: 15px;
  transform: rotate(5deg);
  z-index: -1;
}

.stacked_card.second {
  width: 90%;
  background-color: #dddddd;
  top: 30px;
  transform: rotate(-3deg);
  z-index: -2;
}

.product_card_link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  outline: none;
  z-index: 20;
}

.product_card_link:focus {
  outline: none;
}

.ribbon_tag {
  z-index: 10;
  width: 150px;
  height: 150px;
  position: absolute;
  overflow: hidden;
}

.ribbon_tag.right {
  top: -10px;
  right: -10px;
}

.ribbon_tag.left {
  top: -10px;
  left: -10px;
}

.ribbon_tag::before,
.ribbon_tag::after {
  content: "";
  position: absolute;
}

.ribbon_tag.right::before,
.ribbon_tag.right::after {
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #3d3d3d;
  border-left: 5px solid #3d3d3d;
}

.ribbon_tag.right::before {
  bottom: 0;
  right: 0;
}

.ribbon_tag.left::before,
.ribbon_tag.left::after {
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #3d3d3d;
  border-right: 5px solid #3d3d3d;
}

.ribbon_tag.preorder.left::before,
.ribbon_tag.preorder.left::after {
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 5px solid #0d1b2a;
  border-right: 5px solid #0d1b2a;
}

.ribbon_tag.left::before {
  bottom: 0;
  left: 0;
}

.ribbon_tag.left::after {
  top: 0;
  right: 0;
}

.ribbon_tag span {
  width: 225px;
  padding: 15px 0;
  background-color: #646464;
  display: block;
  position: absolute;
  top: 30px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.ribbon_tag.preorder span {
  background-color: #415a77;
}

.ribbon_tag.right span {
  left: -25px;
  transform: rotate(45deg);
}

.ribbon_tag.left span {
  right: -25px;
  transform: rotate(-45deg);
}

.product_card_body {
  width: 100%;
  height: 70%;
  padding: 1rem;
  position: relative;
}

.product_card .__product_price {
  font-weight: bold;
}

.product_card_body a,
.product_card_body a:hover {
  color: #333333;
}

.product_name h4 {
  padding: 0 0.5rem;
  font-size: 14px;
  text-transform: uppercase;
}

.product_card_body .view_panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  gap: 1rem;
  background-color: rgb(255, 255, 255);
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 21;
}

.product_card_body .view_panel .view_panel_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.8rem;
}

.product_card_body .view_panel .view_panel_text.color-default {
  color: #415a77;
  border: 1px solid #415a77;
}

.product_card_body .view_panel .view_panel_text.color-in-cart {
  color: #26a69a;
  border: 1px solid #26a69a;
}

.product_card_body .view_panel .view_panel_link {
  display: block;
  text-decoration: none;
}

.product_card_body .view_panel .add_to_cart_btn {
  padding: 0.5rem;
  opacity: 0.8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.3s ease-in-out;
}

.product_card_body .view_panel .add_to_cart_btn.bg-color-default {
  background-color: #415a77;
}

.product_card_body .view_panel .add_to_cart_btn.bg-color-in-cart {
  background-color: #26a69a;
}

.product_card_body .view_panel .add_to_cart_btn:hover {
  box-shadow: none;
}

.product_card_body .view_panel .add_to_cart_btn .plus_icon {
  font-size: 8px;
}

.product_card .more_products {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  bottom: 10px;
  right: 10px;
  color: #415a77;
}

.product_card .sold_out {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #cf0000;
}

.product_list_container .product_card:hover,
.slider_wrapper .product_card:hover,
.recommended_product_slider .product_card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.product_list_container .product_card:hover .view_panel,
.slider_wrapper .product_card:hover .view_panel,
.recommended_product_slider .product_card:hover .view_panel {
  height: 50px;
  opacity: 1;
}

.product_card:hover .add_to_cart_btn {
  height: 30px;
  opacity: 1;
}

.product_card_body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_card_footer {
  text-align: center;
}

.caret_webshop {
  font-size: 2rem;
  cursor: pointer;
}

.caret_webshop i {
  transition: all 0.2s ease-out;
}

.caret_webshop.caret_hide > i {
  transform: rotateZ(180deg);
}

/* Shop product page */

.cart_btn {
  background-color: #415a77;
  color: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-radius: 5px;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.cart_btn.in_cart {
  background-color: #26a69a;
}

.cart_btn.disabled {
  cursor: default;
  opacity: 0.3;
}

.slider_container {
  width: 450px;
  margin-top: 1rem;
}

.cart_btn:hover {
  background-color: #778da9;
}

.cart_btn .btn_title {
  text-transform: uppercase;
  font-weight: 600;
}

.product_description {
  background-color: #f1f1f1;
  min-height: 150px;
  border-radius: 10px;
  padding: 1rem;
}

#product-parameters {
  display: flex;
  align-items: center;
  gap: 1rem 3rem;
  flex-wrap: wrap;
}

.shop_form_attributes {
  width: fit-content;
}

.primary_attr_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.primary_attr_flex .img_holder {
  width: 82px;
  height: 82px;
  cursor: pointer;
}

.primary_attr_flex .img_holder img {
  object-fit: scale-down;
}

.primary_attr_flex .img_holder.active {
  opacity: 0.4;
  cursor: not-allowed;
}

.cart_row {
  margin-top: 20px;
}

.product_price {
  font-weight: 700;
  display: inline-block;
  vertical-align: bottom;
  font-size: 22px;
  line-height: 88px;
}

.img_slider {
  cursor: pointer;
}

.img_active {
  opacity: 0.1;
}

#product_quantity {
  width: 80px;
}

.related_products_container {
  margin: 3rem 0;
}

.related_products_title h3,
.groupped_products_title h3 {
  color: #415a77;
  font-weight: bold;
  margin: 5rem 0 3rem 0;
}

/* carousel nav buttons */
.slider_arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  color: #415a77;
  font-size: 20px;
  z-index: 1000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.slider_arrow:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.slider_arrow i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: all 0.2s ease-in-out;
}

.slider_arrow.prev {
  left: -10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.slider_arrow.next {
  right: -10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.slider_arrow.prev:hover i {
  left: 45%;
}
.slider_arrow.next:hover i {
  left: 55%;
}

.recommended_product_slider .slick-slide,
.groupped_products_slider .slick-slide,
.related_products_slider .slick-slide {
  margin: 0 10px 10px 10px;
}

.recommended_product_slider .slick-list,
.groupped_products_slider .slick-list,
.related_products_slider .slick-list {
  margin: 0 auto;
}

.recommended_product_slider .slick-track,
.groupped_products_slider .slick-track,
.related_products_slider .slick-track,
.image_slider .slick-track {
  margin-left: 0;
}

/* lodaer */
.status_backdrop {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 5px;
  z-index: 120;
}

.loader_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Spinner */

.shop_spinner {
  position: relative;
  height: 50px;
  width: 50px;
  display: inline-block;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: #5cb85c;
  animation: rotate 0.8s linear infinite;
}

.shop_spinner.completed {
  animation: none;
  border-color: #5cb85c;
  transition: border 0.5s ease-out;
}

@keyframes rotate {
  50% {
    border-left-color: #418a41;
  }
  75% {
    border-left-color: #5cb85c;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes default_rotate {
  50% {
    border-left-color: #6e6e6e;
  }
  75% {
    border-left-color: #acacac;
  }
  100% {
    transform: rotate(360deg);
  }
}

.shop_spinner .success_icon {
  display: none;
}

.shop_spinner .success_icon.success {
  display: block;
}

.shop_spinner .success_icon::after {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: scaleX(-1) rotate(135deg);
  content: "";
  height: 20px;
  width: 10px;
  border-top: 4px solid #5cb85c;
  border-right: 4px solid #5cb85c;
  transform-origin: left top;
  animation: success-icon 0.8s ease;
}

@keyframes success-icon {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 10px;
    opacity: 1;
  }
  40% {
    height: 20px;
    width: 10px;
    opacity: 1;
  }
  100% {
    height: 20px;
    width: 10px;
    opacity: 1;
  }
}

.shop_default_spinner {
  position: relative;
  height: 50px;
  width: 50px;
  display: inline-block;
  border: 5px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-left-color: #ddd;
  animation: default_rotate 0.8s linear infinite;
}

/* Disabled block */
.disabled_backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
}

.disabled_backdrop.active {
  display: block;
}

.btn_wrapper {
  z-index: 100;
  position: relative;
}

/* toggle switch */
.toggle_switch > input[type="checkbox"] {
  display: none;
}

.toggle_switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.toggle_switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.toggle_switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.toggle_switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.toggle_switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.switch_label {
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 11px;
}

.active_badge {
  margin: 0 auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #a6a6a6;
}

.active_badge.active {
  background-color: #418a41;
}

/* filter sidebar */

.filter_backdrop {
  display: none;
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.filter_backdrop.open {
  display: block;
}

.filter_sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  margin-bottom: 20px;
  background: #fff;
}

.filter_sidebar .sidebar_header {
  background-color: #cacaca;
  padding: 1rem;
}

.filter_sidebar .sidebar_header.base {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sidebar_icon i {
  font-size: 1.8rem;
  cursor: pointer;
  color: #0d1b2a;
}

.sidebar_header .close_filters {
  font-size: 2rem;
  display: block;
}

.open_filters {
  margin-top: 62.7px;
  font-size: 2rem;
  display: block;
}

.open_filters i {
  cursor: pointer;
}

.sidebar_header .close_filters i {
  cursor: pointer;
}

.sidebar_options {
  max-height: 350px;
  overflow-y: auto;
}

.sidebar_item {
  margin-bottom: 3rem;
}

.sidebar_item .sidebar_title {
  color: #415a77;
  font-weight: bold;
}

.options_item .filter_checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.options_item:last-child {
  border: none;
}

.options_item {
  border-bottom: 1px solid #cacaca;
  padding: 10px 5px 10px 10px;
}

.options_item .filter_checkbox input,
.options_item .filter_checkbox label {
  margin: 0;
}

/*Post point alert*/
.shipment-info-alert {
  display: none;
}

.shipment-info-alert.show-alert {
  display: block;
}

/* Mouse Icon */
.mouse-icon-wrapper {
  width: 18px;
  height: 32px;
  transition: all 0.5s ease;
  animation: mouse-move 3s ease-in 1s infinite;
  opacity: 1;
}

.mouse-icon-wrapper.clicked {
  opacity: 0;
}

.mouse-btn-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.mouse-left-btn {
  width: 8px;
  height: 8px;
  border-radius: 100% 0 0 0;
  background-color: #aaaaaa;
  transition: all 1s ease-in-out;
  animation: mouse-click 3s ease-in-out 1s infinite;
}

.mouse-right-btn {
  width: 8px;
  height: 8px;
  border-radius: 0 100% 0 0;
  background-color: #aaaaaa;
}

.mouse-base {
  width: 18px;
  height: 22px;
  border-radius: 0 0 50% 50%;
  background-color: #aaaaaa;
  margin-top: 2px;
}

@keyframes mouse-move {
  0% {
    transform: rotate(0) translate(0, 0);
  }
  10% {
    transform: rotate(-6deg) translate(0, 3px);
  }
  25% {
    transform: rotate(5deg) translate(10px, 3px);
  }
  30% {
    transform: rotate(10deg) translate(10px, 3px);
  }
  50% {
    transform: rotate(0) translate(0, 0);
  }
  100% {
    transform: rotate(0) translate(0, 0);
  }
}

@keyframes mouse-click {
  0% {
    background-color: #aaaaaa;
  }
  10% {
    background-color: #707070;
  }
  25% {
    background-color: #707070;
  }
  30% {
    background-color: #707070;
  }
  50% {
    background-color: #aaaaaa;
  }
  100% {
    background-color: #aaaaaa;
  }
}

@media only screen and (max-width: 1200px) {
  .product_list_container {
    grid-template-columns: repeat(3, auto);
  }
}

@media only screen and (max-width: 991px) {
  .product_list_container {
    grid-template-columns: repeat(2, auto);
  }
}

@media only screen and (max-width: 610px) {
  .product_list_container {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }
}

@media only screen and (max-width: 460px) {
  .product_img.default {
    font-size: 20rem;
  }

  .shop_form_attributes {
    width: 100%;
  }
}


/* tv maci banner start */
.tv-maci-banner-container{
    z-index: 10005;
    position: absolute;
    background-color: whitesmoke;
    padding: 12px;
    border-top: 1px solid #ccc;
    width: 100%;
}
.tv-maci-banner-wrapper {

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 12px;
    background-color: #cce5ff;
    border: 1px solid #b8daff;
    border-radius: 4px;
}

.tv-maci-banner-content h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #004085;
}

.tv-maci-banner-content p {
    font-size: 16px;
    color: #004085;
}

@media (max-width: 768px) {
    .tv-maci-banner-wrapper {
        padding: 8px;
        gap: 24px;
    }

    .tv-maci-banner-content h2 {
        font-size: 16px;
    }

    .tv-maci-banner-content p {
        font-size: 14px;
    }
}

.tv-maci-banner-content.hidden {
    display: none;
}


.tv-maci-banner-close {
    /* reset button default */
    background: none;
    border: none;

    cursor: pointer;
    margin: 0;
    padding: 24px;
    font-size: 20px;
    color: #004085;
}

/* tv maci banner end */
