@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.secondary-nav {
    min-height: 40px;
    background: #333333;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    position: relative;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.secondary-nav .icon-bar {
    background-color: #fff;
}

.sub-menu-toggle {
    margin-right: 15px;
    padding: 0 10px;
}

.secondary-brand {
    margin-left: 15px;
}

.secondary-brand-text {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .4rem;
    font-family: 'Poppins', sans-serif;
}

.secondary-menu-items {
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    font-size: 1.4rem;
}

.secondary-menu-items a,
.secondary-menu-items span {
    text-decoration: none;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.secondary-menu-items a:hover,
.secondary-menu-items span:hover,
.secondary-menu-items .active {
    opacity: .8;
    border-bottom: 3px solid;
}

.secondary-menu-items .toggle-title:hover {
    border: none;
}

.secondary-menu-item.hidden-item {
    display: none;
}

#secondary-nav {
    position: unset;
    background: transparent;
    border: none;
}

.extended-sub-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.extended-sub-menu .toggle-title {
    font-size: 1.1rem;
}

.sub-menu-more-toggle {
    display: block;
    padding: 0 5px;
}

#secondary-extended_nav {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    background: #0b82c4;
    border-top: 1px solid;
}

#secondary-extended_nav .secondary-menu-items {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
}

@media (max-width: 1020px) {
    .secondary-nav {
        gap: unset;
    }
}

@media (max-width: 1005px) {
    .secondary-brand {
        display: none;
    }

    .secondary-nav {
        justify-content: right;
    }

    .secondary-menu-items {
        font-size: 1.2rem;
        gap: 1rem;
    }
}

@media (max-width: 896px) {
    .secondary-menu-items {
        font-size: 1.2rem;
        gap: 1rem;
    }
}

@media (max-width: 767px) {
    #secondary-nav {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;
        background: #0b82c4;
        border-top: 1px solid;
    }

    .secondary-menu-items {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px;
        font-size: 1.6rem;
    }

    .extended-sub-menu {
        display: none;
    }

    #secondary-extended_nav {
        display: none;
    }

    .secondary-menu-item.hidden-item {
        display: block;
    }

    .flex-placeholder {
        display: none;
    }

    .secondary-brand {
        display: block;
    }

    .secondary-nav {
        justify-content: space-between;
    }
}
