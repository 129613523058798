.photo-not-found-container {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.photo-not-found-container h2 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .photo-not-found-container h2 {
        font-size: 24px;
    }

    .photo-not-found-container img {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 486px) {
    .photo-not-found-container h2 {
        white-space: normal;
    }
}



.photo-not-found-container p {
    text-align: center;
    color: #6c757d;
}

