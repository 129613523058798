.email-modal{
    display: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.email-modal-content{
    position: relative;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 25px;
    border: 1px solid #888;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.close-modal-btn{
    position: absolute;
    color: #555e63;
    float: right;
    font-size: 20px;
    font-weight: bold;
    top: -4px;
    right: 3px;
    cursor: pointer;
}

.resend-btn{
    cursor: pointer;
    background-color: #555e63;
    color: white;
    border-color: #555e63;
    border-radius: 2px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}







