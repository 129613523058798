@import "_webshop.css";
@import "secondary-nav.css";
@import "navigation.css";
@import "not_found.css";
@import "photo-not-found.css";

body {
    /*font-family: 'Raleway', sans-serif;*/
    font-family: 'Quicksand', sans-serif;
    background-color: #f1f1f1;
    letter-spacing: 0.7px;
}

a {
    cursor: pointer
}

p {
    font-size: 13px;
}

label {
    font-size: 11px;
}

/*
.container-fluid {
    max-width: 2000px !important;
}
*/
.checkbox label {
    line-height: 20px
}

.checkbox label {
    font-size: 12px;
}

span.hl {
    font-weight: bold;
}

[data-href] {
    cursor: pointer;
}

.form-container {
    margin-bottom: 30px
}

.text-right {
    text-align: right;
}

.text-white {
    color: white
}

a.text-white:hover {
    color: white;
    opacity: 0.6;
}

.text-white.muted {
    opacity: 0.6;
}


.form-control.invert, .textarea-invert {
    border: 1px solid rgba(255,255,255,0.6) !important;
    background-color: rgba(0,0,0,0.6) !important;
    color: white !important;
    outline: none !important;
}

.content-header .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}

.content-header .input-group .form-control:not(:first-child):not(:last-child) .btn, .input-group-addon:not(:first-child):not(:last-child) .btn, .input-group-btn:not(:first-child):not(:last-child) .btn {
    border-radius: 0 !important;
}
.content-header-search .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}

.content-header-search .input-group .form-control:not(:first-child):not(:last-child) .btn, .input-group-addon:not(:first-child):not(:last-child) .btn, .input-group-btn:not(:first-child):not(:last-child) .btn {
    border-radius: 0 !important;
}

.content-header-search .form-control {
    transition: background-color 0.2s ease-in-out;
    box-shadow: none
}
.content-header-search .form-control:focus {
    border: none;
    box-shadow: none
}


.content-header-search .form-control.danger {
    background-color: rgb(255, 204, 204) !important;
}

.btn-info {
    background-color: #26A69A;
    color: white;
    border-color: #26A69A;
}

.label-info {
    background-color: #26A69A;
}

.btn.disabled:hover {
    background-color: rgba(0,0,0,0.2);
    border-color: rgba(0,0,0,0.2);
}

.btn-info:hover,
.btn-info:active:hover,
.btn-info:focus,
.btn-info:active:focus {
    background-color: #00897B;
    color: white;
    border-color: #00897B;
}


.btn-fb,
.btn-fb:hover,
.btn-fb:active:hover,
.btn-fb:focus,
.btn-fb:active:focus {
    background-color: #4267b2 !important;
    border-color: #4267b2 !important;
    color: white !important;
}

.radio label {
    font-size: 12px;
    line-height: 20px;
}

.form-control {
    border-radius: 2px;
}

.form-group.required .control-label:after {
    content:"*";
    color:red;
}

.content-header .input-group .btn {
    background-color: white;
    color: rgba(0,0,0,0.4);
    border-radius: 0px !important;
}

.content-header .input-group input {
    box-shadow: none !important;
    border:none;
}

.content-header .input-group input,
.content-header .input-group input:focus,
.content-header .input-group input:active:focus,
.content-header .input-group input:hover:active:focus,
.content-header .input-group input:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}
.content-header-search .input-group .btn {
    background-color: white;
    color: rgba(0,0,0,0.4);
}

.content-header-search .input-group input {
    box-shadow: none !important;
    border:none;
}

.content-header-search .input-group input,
.content-header-search .input-group input:focus,
.content-header-search .input-group input:active:focus,
.content-header-search .input-group input:hover:active:focus,
.content-header-search .input-group input:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none !important;
}


/* hero section */

.hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.hero-section.home {
    height: 300px;
    padding-top: 60px;
    /*margin-bottom: -38px;*/
}

.hero-section.results {
    padding: 20px 0 15px 0;
}

.hero-section.item {
    padding: 20px 0 20px 0;
}

.hero-section-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: -1;
    position: absolute;
}

.hero-section-bg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-title {
    z-index: 10;
    display: none;
    text-align: center;
    position: relative;
    padding-bottom: 10px
}

.hero-title h5 {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    margin-bottom: 5px;
}

.hero-title h6 {
    font-weight: bold;
    font-size: 13px;
    color: white;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
}

.home .hero-title {
    display: block;
}

.search-container {
    width: 75%;
    max-width: 800px;
    margin: 0 auto;
}

@media (max-width: 767px) {
    .search-container {
        width: 100%;
        padding: 0 15px;
    }
}

.search-container .input-group {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}



.search-container .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}
.search-container .input-group .form-control:not(:first-child):not(:last-child) .btn, .input-group-addon:not(:first-child):not(:last-child) .btn, .input-group-btn:not(:first-child):not(:last-child) .btn {
    border-radius: 0 !important;
}
.search-container-search .input-group .form-control:not(:first-child):not(:last-child), .input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}
.search-container-search .input-group .form-control:not(:first-child):not(:last-child) .btn, .input-group-addon:not(:first-child):not(:last-child) .btn, .input-group-btn:not(:first-child):not(:last-child) .btn {
    border-radius: 0 !important;
}
.search-container .form-control {
    transition: background-color 0.2s ease-in-out;
    box-shadow: none
}
.search-container .form-control:focus {
    border: none;
    box-shadow: none
}
.search-container .form-control.danger {
    background-color: rgb(255, 204, 204) !important;
}
.search-container .input-group .btn {
    background-color: white;
    color: rgba(0,0,0,0.4);
    border-radius: 0px !important;
}
.search-container .input-group input {
    box-shadow: none !important;
    border:none;
}
.search-container .input-group input,
.search-container .input-group input:focus,
.search-container .input-group input:active:focus,
.search-container .input-group input:hover:active:focus,
.search-container .input-group input:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}
.search-container .input-group .btn {
    background-color: white;
    color: rgba(0,0,0,0.4);
}
.search-container .input-group input {
    box-shadow: none !important;
    border:none;
}
.search-container .input-group input,
.search-container .input-group input:focus,
.search-container .input-group input:active:focus,
.search-container .input-group input:hover:active:focus,
.search-container .input-group input:active {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.results-sort-dropdown {
    padding-top: 10px;
    margin-bottom: 10px;
}

#results_sort {
    cursor: pointer;
}

#active_sort {
    font-weight: bold
}

/* hero section end */







@media (max-width: 767px) {
    .login-header h4 {
        font-size: 15px;
    }
}

.login-group input[type="text"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
}
.login-group input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.login-group .form-control:focus {
    z-index: 2;
}
.login-group .form-control {
    position: relative;
}

.btn {
    border-radius: 2px;
}

.btn-default {
    background-color: #f9f9f9;
    border-color: #eee;
}

.btn-default:hover {
    background: #eee;
    border-color: #ddd;
}



.navbar {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
}

.navbar-brand {
    position: relative;
    padding-left: 50px;
  }

  .navbar-brand img {
    position: absolute;
    width: 40px;
    top: 6px;
    left: 5px;
  }


.btn-container .btn {
    width: 100%;
}

.content {
    min-height: calc(100vh - 80px);
    position: relative;
    background-size: cover;
    background-attachment: fixed;
}

.content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,0.8);
}

.content.home {
    padding-top: 30px;
}

.teaser-text {
    margin-top: -38px;
}

.gradient-navbar {
    background-image: url(../img/bg_full.jpg);
    background-repeat: repeat-y;
    background-size: cover;
    background-position: center;
    background-color: #667177;
    border: none;
}

.gradient-navbar .container-fluid {
    background-color: rgba(0,0,0,0.2)
}

@media (max-width: 767px) {
    .gradient-navbar {
        background-image: none;
    }
    .gradient-navbar .navbar-header {
        background-image: url(../img/bg_full.jpg);
        background-repeat: repeat-y;
        background-size: cover;
        background-position: center;
    }
}

.gradient-navbar a {
    color: white !important;
}

.gradient-navbar li > a {
    font-size: 13px;
}

.gradient-navbar .dropdown-menu a {
    color: rgb(49, 49, 49) !important;
}

@media (max-width: 767px) {
    .gradient-navbar .dropdown-menu a {
        color: white !important;
    }

}

.gradient-navbar .navbar-toggle,
.gradient-navbar .navbar-toggle:active,
.gradient-navbar .navbar-toggle:visited,
.gradient-navbar .navbar-toggle:active:focus,
.gradient-navbar .navbar-toggle:focus {
    border: none;
    background-color: transparent;
}

.gradient-navbar .navbar-toggle > .icon-bar {
    background-color: white;
}

.dropdown-menu {
    min-width: 250px;
}

/*
.dropdown-menu a {
    font-size: 14px !important;
}
*/

.dropdown-menu .tooltip-inner {
    text-align: left;
}

.dropdown-menu .balance {
    pointer-events: none;

}

.dropdown-menu .balance span {
    float: right
}

.navbar {
    margin-bottom: 0;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: #555;
    background-color: rgba(255,255,255,0.2);
}


.under-nav {
    background-color: white;
    text-align: left;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    position: relative;
}

.under-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.under-nav li {
    display: inline-block;
    float: left;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: relative;
}

.under-nav li.active {
    background-color: #555e63;
}

.under-nav li.active > a {
    color: white !important;
}

.under-nav li:hover {
    background-color: #e2e2e2;

}
.under-nav li.active:hover {
    background-color: #667177;
}

.under-nav li {
    border-right: 1px solid #dadada;
}

.under-nav li:last-child {
    border-right: 1px solid transparent;
}

.under-nav li > a {
    color: black;
    font-weight: 500;
    text-decoration: none;
    font-size: 13px;
    padding: 6px 15px;
    line-height: 30px;
}

@media (max-width: 767px) {
    .under-nav li > a {
        color: black;
        font-weight: 500;
        text-decoration: none;
        font-size: 13px;
        padding: 0;
        padding: 2px 12px;
        line-height: 30px;
        display: table;
    }

    .under-nav li > a > span > i {
        font-size: 18px;
        color: #5c5c5c
    }

    .under-nav li.active > a {
        color: white !important;
    }

    .under-nav li.active > a > span > i {
        color: white !important;
    }
}

.under-nav-left {
    float: left;
}

.under-nav-right {
    float: right;
}

.sub-menu {
    position: absolute;
    background-color: white;
    min-width: 100%;
    left: 0;
    top: 100%;
    display: none;
    z-index: 10000;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);

}

.sub-menu li {
    display: block;
    width: 100%;
}

.has-sub:hover .sub-menu,
.has-sub:hover .cart-sub {
    display: block;
}

.cart-sub {
    position: absolute;
    background-color: white;
    min-width: 100%;
    right: -1px;
    top: 100%;
    display: none;
    z-index: 10000;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.cart-sub-inner {
    width: 350px;
}

.cart-sub-list {
    padding: 15px 15px 0 15px;
    max-height: 400px;
    overflow-y: auto;
}

.empty .cart-sub-list {
    padding: 0;
}

.cart-sub-item {
    display: flex;
    margin-bottom: 15px;
}

.cart-sub-item .i {
    width: 20%;
    padding-bottom: 20%;
    float: left;
    background-color: #f1f1f1;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cart-sub-item .i > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    font-size: 30px;
    color: #999;
}

.cart-sub-item .c {
    width: 80%;
    float: left;
    padding-left: 10px;
    box-sizing: border-box;
    position: relative;
}

.cart-sub-bottom {
    padding: 15px;
    width: 100%;
}

.c-title {
    width: 100%;
    font-size: 12px;
}

.c-remove {
    position: absolute;
    top: 0;
    right: 0;
    color: #dc3545;
    cursor: pointer;
}




.btn-primary {
    background-color: #555e63;
    color: white;
    border-color: #555e63;
}

.btn-primary:hover,
.btn-primary:active:hover,
.btn-primary:focus,
.btn-primary:active:focus {
    background-color: #667177;
    color: white;
    border-color: #667177;
}


.nav-alert {
    background-color: black;
    color: white;
    position: relative;
    font-size: 12px;
    max-height: 100px;
    overflow: hidden;
}

.nav-alert.collapsed {
    max-height: 0;
}

.nav-alert div {
    position: relative;
    padding: 6px 25px 6px 15px;
}

.nav-alert span {
    position: absolute;
    top: 6px;
    right: 15px;
    cursor: pointer;
}


.open a {
    background-color: transparent !important;
}

.login-hero {
    background-image: url(../img/bg_full.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 400px;
    color: white;
    text-align: center;
}

.login-hero-container {
    padding-bottom: 150px;
    padding-top: 100px;
    position: relative;
}

.login-box {
    position: absolute;
    top: 30px;
    right: 15px;
}

.login-box a {
    color: white;
    font-size: 12px;
}


.login-hero-logo {
    width: 100%;
    height: 180px;
    background-image: url('../svg/mtva_archivum_logo_white.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 15px;
}

.login-nav-logo {
    width: 200px;
    height: 100px;
    background-image: url('../svg/mtva_archivum_logo_white.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    margin-bottom: 15px;
    position: absolute;
    top: 15px;
    left: 0;
}

.login-nav-title {
    height: 100px;
    line-height: 100px;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 15px;
    position: absolute;
    top: 15px;
    left: 110px;
    color: white !important;
    text-decoration: none;
}
.login-nav-title:hover {

    color: white !important;
    text-decoration: none;
}

.login-content {
    margin-top: -80px;
}



/* new login stuff */

.login-container {
    padding-top: 80px;
}

.login-container h4 {
    text-align: center;
    margin: 0;
    padding: 20px;
    margin-bottom: 50px;
    color: rgb(153, 153, 153) !important;
    text-decoration: none !important;
}

@media (max-width: 767px) {
    .login-container h4 {
        margin-bottom: 5px;
    }
}

.login-container h4.active {
    border-bottom: 2px solid rgb(87, 87, 87) !important;
    color: rgb(87, 87, 87) !important;
}
.login-container a {
    color: black;
    text-decoration: none;
}
















/* ___________________________________ */
.public-profile-content {
    margin-top: -230px;
    margin-bottom: 100px;
}

.pdf-img {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(../img/pdf.jpg);
}

.public-profile-hero {
    margin: 0 -15px;
    padding-bottom: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /*background-image: url(../img/news3.jpg);*/
    position: relative;
}

.public-profile-hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.1);
    color: white;
}


.public-profile-hero-titles {
    padding: 15px;
    padding-top: 50px;
}

.public-profile-hero-titles hr {
    margin: 5px 0;
}

.preview-img {
    padding-bottom: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    background-image: url(../img/stock.jpg);
}


.card {
    background-color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    margin-bottom: 30px;
}

.card-img {
    width: 100%;
    padding-bottom: 160px;
    background-color: #dedede;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.card-img.news, .public-profile-hero.photo {
    background-image: url('../img/news2.jpg');
}

.card-img.music, .public-profile-hero.music {
    background-image: url('../img/music.jpg');
}

.card-img.film, .public-profile-hero.film {
    background-image: url('../img/film.jpg');
}

.card-img.nava, .public-profile-hero.nava {
    background-image: url('../img/nava.jpg');
    background-position: bottom center;
}

.card-text {
    min-height: 310px;
    padding: 0 15px 15px 15px;
    position: relative;
}

.card-text h4 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
}

.card-text p {
    font-size: 12px;
}

.card-text .btn-container {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
}

.white-container {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    background-color: white;
    padding: 0 15px 15px 15px;
    margin-bottom: 30px;
    display: block;
}

.gradient-title {
    background-image: url(../img/bg_full.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 50px;
    margin: 0 -15px 10px -15px;
    padding: 0 15px;
    line-height: 50px;
    color: white;
}

.photo-header {
    margin: 0 -15px;
    background-color: #dedede;
    padding-bottom: 40%;
    position: relative;
    overflow: hidden;
}

.photo-header-img {
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -10px;
    top: -10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /*
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    */
    z-index: 100;
}

.photo-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 2rem 0;
}

.loading-spinner {
    width: 30px;
    height: 30px;
    border: 3px solid #e0e0e0;
    border-top: 3px solid #808080;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.photo-header.bigger {
    padding-bottom: 60%;
}

#search_form .input-group {
    position: relative
}

.suggester {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    z-index: 1000;
    padding: 5px 15px;
    display: none;
}

.suggester span {
    display: block;
    cursor: pointer;
    padding: 2px 0;
}

#search_form .btn-default {
    border-color: white;
}

.search-submit {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

#search_plus_btn {
    transition: color 0.2s ease-in-out;
}
#search_plus_btn.on {
    color: #03A9F4;
}


.search-plus-inner {
    position: relative;
}



.search-plus-inner.advanced {
    padding-bottom: 20px
}

.search-plus-switch {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0)
}

.slide-container {
    padding-top: 15px !important
}

.datepicker-row {
    padding-top: 10px;
}

.datepicker-row input,
.datepicker-row select {
    border: none;
}

.datepicker-row .col {
    padding: 0 5px
}

#filter-tags {
    padding: 0 15px;
    /*margin-bottom: -20px;*/
}

.filter-tag {
    float: left;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px
}

@media (max-width: 767px) {
    .filter-tag {
        margin-bottom: 5px
    }
}

.filter-tag i {
    color: #999;
    margin-left: 5px;
}

.filter-tag {
    border: none !important;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}


.map-container {
    margin-top: 10px;
    padding: 0px 10px;

}

#map {
    position: relative;
    height: 50px;
    background-color: transparent;
}

#map.loading .col span {
    height: 0% !important;
}

#map.loading .col {
    opacity: 0;
}

.map-loader {
    position: absolute;
    width: 0%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.1);
    animation: map_loading 2s 1;
}

@keyframes map_loading {
    from {
        width: 0%;
        opacity: 1;
    }
    to {
        width: 99%;
        opacity: 0;
    }
}

#map .col {
    position: absolute;
    bottom: 0;
    top: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}
#map .col:hover {
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,0.05);
}

#map .col span {
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    transition: height 0.3s ease-in-out;
}

#map .col:hover span {
    background-color: rgba(0,0,0,0.5);
}

#map .col.active {
    /*background-color: #fff;*/
    background-color: rgba(0,0,0,0.05);
}

#map .col.active span {
    background-color: #26A69A;
}

#map .col.active:hover span {
    background-color: #00897B;
}







.slide-container {
    padding: 10px 10px;
}

#slider-range.dot .active-range {
    padding: 0 5px;
    margin: 0 -5px;
}

#amount {
    font-weight: bold;
    text-align: center;
    margin-bottom: 3px;
}

#amount select {
    background-color: transparent !important;
    border: none !important;
}

#amount select:active,
#amount select:active:focus,
#amount select:focus {
    outline: none !important;
}

.ui-slider-handle, #slider-range {
    cursor: pointer !important;
}

.ui-slider .ui-slider-handle {
    z-index: 1 !important;
}

.ui-slider .ui-slider-range {
    background-color: rgba(0,0,0,0.05) !important;
}

.active-range {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #a8dbd0;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23005138' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");    z-index: 0;
    border-radius: 3px
}


.footer {
    background-color: rgb(27, 27, 27);
    color: white;
    padding: 30px 0;
}


.footer.fixed {
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10000;
    transition: all 0.3s ease-in-out;
}

.footer.fixed.toggled {
    bottom: 0;
    top: auto;
}

.handle {
    display: none;
    color: white;
    text-align: center;
    line-height: 20px;
    transition: all 0.2s ease-in-out
}

/*
.footer.fixed .handle > span {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
    transition: all 0.3s ease-in-out
}
.footer.fixed.toggled .handle > span{
    /*transform: rotate(180deg);
}

.footer.fixed.toggled .handle {
    top: 5px;
}

.footer.fixed .handle {
    cursor: pointer;
    display: block;
    position: absolute;
    top: -20px;
    width: 60px;
    height: 20px;
    background-color: rgb(27, 27, 27);
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
*/

.footer.fixed .handle {
    display: block;
    position: absolute;
    z-index: 100;
    right: 30px;
    top: -70px;
    background-color: rgb(93, 172, 196);
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    opacity: 1;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    transition: all 0.2s ease-in-out ;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 10px 0;
}
.footer li {
    margin: 0;
    padding: 0;
    display: inline-block;
    padding-right: 20px;
}
.footer li > a {
    font-size: 12px;
    color: white;
}

.footer .small {
    font-size: 0.7em
}

.requests_nav {
    background-color: rgb(43, 43, 43);
    color: white;
    padding: 5px 0;
    margin-top: -20px;
    font-size: 12px;
}

hr {
    border-color: rgb(209, 209, 209);
}

.requests-container, .orders-container, .helpdesk-container, .bookmarks-container {
    padding-top: 30px;
}

.orders-container {
    margin-bottom: 20px;
}

.helpdesk-container textarea {
    min-height: 200px;
}


.requests-sidebar {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*
.requests-sidebar li {

}
*/

.requests-sidebar li > a {
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin: 0 -5px 0 -5px;
    font-size: 13px;
    text-decoration: none;
    color: black;
    display: block;
    padding: 5px 5px;
}

.requests-sidebar li:hover > a {
    background-color: white;
}

.requests-sidebar li.active > a {
    background-color: white;
}

.sidebar-sub-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 30px;
}


.notif-item {
    position: relative;
    min-height: 40px;
    margin-bottom: 1px;
    margin-left: -5px;
    margin-right: -5px;
    padding: 5px 5px 1px 5px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.notif-item:hover {
    background-color: rgba(255,255,255,0.5);
}

.notif-item.unread {
    background-color: white;
}

.notif-icon {
    width: 40px;
    padding-bottom: 40px;
    background-color: white;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 40px;
}

.notif-icon .glyphicon-ok {
    color: #20c997;
}

.notif-item.unread .notif-icon {
    background-color: #f1f1f1;
}


.notif-icon span {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
}

.notif-content {
    padding-left: 50px;
}

.notif-date {
    position: absolute;
    right: 5px;
    top: 5px;
}


.request-list {
    margin-bottom: 100px;
}

.request-item {
    background-color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    padding: 10px 15px 5px 15px;
    cursor: pointer;
    margin-bottom: 10px;
    color: black;
    text-decoration: none;
    display: block;
    border-radius: 4px
}

.request-item p {
    margin-bottom: 10px;
}

.request-details {
    border-top: 1px solid #f1f1f1;
    padding-top: 5px;
    position: relative;
}

.request-details span {
    line-height: 30px
}

.request-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    padding: 5px 15px;
    box-sizing: border-box;
    text-align: right;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
}

.request-item:hover .request-actions {
    height: 100%;
    opacity: 0.5;
    pointer-events: auto;
}
.request-item:hover .request-actions:hover {
    opacity: 1;
    color: black !important;
}


.request-status {
    text-align: right;
    margin-top: -10px;
    padding-top: 10px;
    border-left: 1px solid #f1f1f1;
}

@media (max-width: 767px) {
    .request-status {
        text-align: left
    }
}

textarea {
    resize: vertical;
}




.content-header {
    min-height: 340px;
    padding-top: 60px;
    position: relative;
    margin-bottom: -38px;
}

.content-header-img {
    /*background-image: url(../img/ph/C__LA19620622003.jpg);*/
    background-size: cover;
    background-position:  center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
}

@media (max-width: 767px) {
    .content-header-img {
        background-size: cover;
        background-position: center !important;
    }
}

.content-header-img.photobank {
    background-position: center -100px;
}

.content-header-img.news_archive {
    filter: grayscale(70%);
}
.content-header-img.film {
    filter: grayscale(70%);

}

.content-header h5 {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 30px;
    margin-bottom: 0px;
}

.content-header p {
    color: white;
    text-align: center;
}

.content-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    /*background-color: rgba(255,255,255,0.3);*/
}

.content-header-overlay.sheet_music,
.content-header-overlay.welcome,
.content-header-overlay.radio,
.content-header-overlay.film,
.content-header-overlay.news_archive {
    background-color: rgba(0,0,0,0.4);
}

.content-header-overlay.photobank {
    background-color: rgba(0,0,0,0.3);
}


.content-header-search {
    padding-top: 20px;
    padding-bottom: 20px;

}



.content-header-search .btn-container > .btn {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.05);
}

.content-header-search .input-group {
    border-radius: 4px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.photobank-container {
    padding-top: 0px;
}
.photobank-container.home {
    padding-top: 0px;
    /* should be 0 when line is there */
}
/*
.photobank-container.results {
    padding-top: 15px;
}
*/

.list-header {
    font-size: 13px;
    margin-bottom: 10px;
    text-align: center;
    background-image: url(../img/bg_gradient_a.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}

@media (max-width: 767px) {
    .list-header {
        background-image: none;
        background-color: #f1f1f1;
    }
}

.nav-tabs.nav-justified>li {
    display: table-cell;
    width: 1%;
}

.nav-tabs.nav-justified>.active>a, .nav-tabs.nav-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:hover {

    border: none;
    color: black
}

.nav-tabs>li>a, .nav-tabs>li>a:focus, .nav-tabs>li>a:hover {
    color: #555;
    cursor: default;
    background-color: transparent;
    border: none;

}

.list-header .nav-tabs {
    background-color: rgba(255,255,255,0.3)
}

.nav-tabs.nav-justified>li>a {
    border-bottom: none;
    border-radius: 0;
    cursor: pointer;
    font-weight: bold
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #f1f1f1;
    border: none;
    border-bottom-color: transparent;
}

@media (max-width: 767px) {
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
        color: #555;
        cursor: default;
        background-color: white;
        border: none;
        border-bottom-color: transparent;
    }
}



.photo-item {
    margin-bottom: 30px;
}

.photo-item-inner {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    background-color: white;
    cursor: pointer;
    position: relative;
    display: block;
    color: black;
    text-decoration: none;
}

.photo-item-inner:hover,
.photo-item-inner:focus,
.photo-item-inner:active {
    color: black;
    text-decoration: none;
}

.photo-item-details {
    padding: 15px 15px 5px 15px;
}

.photo-item-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(134, 134, 134, 0.4);
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.photo-item-checkbox:hover {
    border: 2px solid rgba(134, 134, 134, 1);
    background-color: rgba(134, 134, 134, 0.2);
}


.photo-item-img {
    padding-bottom: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    border-bottom: 1px solid #f1f1f1;
}

.photo-container {
    margin: 0 auto;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#technical_details_toggle {
    cursor: pointer;
}

#technical_details_toggle i {
    transition: all 0.3s ease-in-out;
}

#technical_details_toggle.active i {
    transform: rotateZ(180deg);
}

#technical_details {
    display: none;
}

#technical_details.active {
    display: block;
}

.material-size .radio {
    position: relative;
}

.size-price {
    position: absolute;
    top: 1px;
    bottom: 0;
    right: 0;
    line-height: 20px;
    font-weight: bold;
}

.size-alert {
    color: red;
    padding: 0 15px;
    font-size: 13px
}

.paper-gallery,
.canvas-gallery {
    height: 450px;
    position: relative;
    text-align: center;
    margin-bottom: 10px
}

.paper-gallery img,
.canvas-gallery img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    z-index: 0;

}

.paper-arrow-left,
.paper-arrow-right,
.canvas-arrow-left,
.canvas-arrow-right {
    position: absolute;
    transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    -webkit-transform: translateX(0) translateY(-50%);
    -moz-transform: translateX(0) translateY(-50%);
    -o-transform: translateX(0) translateY(-50%);
    top: 50%;
    cursor: pointer;
    z-index: 1;
}

.paper-arrow-left,
.canvas-arrow-left {
    left: 10px;
}

.paper-arrow-right,
.canvas-arrow-right {
    right: 10px;
}

.canvas-margin {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 0px solid rgba(255, 173, 173, 0.4);
}

.canvas-margin-tip {
    position: absolute;
    transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    -webkit-transform: translateX(-50%) translateY(0);
    -moz-transform: translateX(-50%) translateY(0);
    -o-transform: translateX(-50%) translateY(0);
    left: 50%;
    top: 0;
    background-color: rgba(0,0,0, 0.8);
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
    cursor: pointer;
}

.tooltip-inner {
    text-align: left !important;
}

.canvas-settings {
    margin-bottom: 15px;
    display: none;
}

.material-size {
    display: none;
}

.material-sizes {
    max-height: 200px;
    overflow: auto;
    margin-right: -15px;
    padding-right: 15px;
}

/*
.photo-container.loading {

}
*/

.photo-container img {
    transition: all 0.4s ease-in-out;
    filter: blur(0px);
}

.loading .img-responsive {
    display: none;
    filter: blur(3px);
}

.img-small , .load-div {
    display: none;
    width: 100%;
}

.img-small {
    position: relative;
}

.load-div {
    position: absolute;
    color: red;
    width: 15%;
}

/*
.load-div img {

}
*/

.photo-modal .img-small {
    height: 100%;
    width: auto;
    min-height: 50vh;
}

.loading .img-small {
    display: block;
    filter: blur(3px);
    z-index: 2;
}

.loading .load-div {
    display: block;
    z-index: 3;
    -webkit-animation: rotate-center 0.8s ease-in-out infinite alternate both;
    animation: rotate-center 0.8s ease-in-out infinite alternate both;
}

.load-div.download-loader {
    position: unset;
}

#photo_download_modal .modal-dialog .modal-content {
   background: #ededed;
}

#photo_download_modal .loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




.fs-toggle {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0,0,0,0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 20px;
    z-index: 100;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.photo-container:hover .fs-toggle {
    opacity: 1;
}

.rotate-toggle {
    position: absolute;
    top: 5px;
    right: 55px;
    background-color: rgba(0,0,0,0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 20px;
    z-index: 100;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.photo-container:hover .rotate-toggle {
    opacity: 1;
}

.photo-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 100000;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.photo-modal.toggled {
    visibility: visible;
    opacity: 1;
}

.photo-modal-inner {
    width: 100%;
    height: 100%;
    position: relative;
}

.photo-modal-inner .img-container {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 95vw;
    max-height: 95vh;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
}

.img-container img {
    max-height: 95vh;
    margin: 0 auto;
}

/*
.img-container #modal_img {

}
*/

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tile {
    overflow: hidden;
}

.tile .photo {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.3s ease-in-out;
}

.photo-page-details {
    padding-top: 15px;
    position: relative;
}

.photo-page-description {
    margin-bottom: 10px;
    position: relative;
}

.photo-page-description.init {
    display: none;
}

.photo-page-description.limited {
    max-height: 150px;
    overflow: hidden;
}

.photo-page-description-toggle {
    text-align: center;
    padding: 50px 0 0 0;
    cursor: pointer;
    display: none;
    position: absolute;
    height: 80px;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}

.photo-page-description.limited .photo-page-description-toggle {
    display: block;
}

.photo-technical {
    max-height: 80px;
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease-in-out;
}

.photo-technical .overlay {
    position: absolute;
    visibility: visible;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    opacity: 1;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}

.photo-technical.toggled {
    max-height: 500px;
}

.photo-technical.toggled .overlay{
    opacity: 0;
    visibility: none;
    z-index: -1;
}

.photo-technical-toggle {
    padding: 5px 0;
    font-size: 11px;
    cursor: pointer;
    margin-bottom: 5px
}

.photo-size-guide {
    padding: 10px 0 0 0 ;
    min-height: 60px;
    font-size: 12px;
    position: relative;
}




.purchase-options {
    display: none;
}

.download-options .checkbox {
    margin-bottom: 5px;
}

.download-options ul {
    font-size: 10px;
    margin-bottom: 15px
}




.fb-share-button > span {
    height: 24px !important;
}

.photo-tags {
    padding-top: 30px;
}

.photo-tags .btn {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    margin-bottom: 5px;
}

.photo-list.recommended {
    margin-bottom: 50px;
}

.photo-list.related {
    margin-bottom: 15px;
}

.photo-list {
    position: relative;
}

.arrow-up {
    position: fixed;
    z-index: 100;
    right: 95px;
    bottom: 20px;
    background-color: rgb(93, 172, 196);
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    opacity: 1;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    transition: all 0.2s ease-in-out ;
}

.arrow-up.noshow {
    opacity: 0;

}

.btn-row {
    margin-bottom: 30px;
}

.photo-list {
    margin-bottom: 50px;
}

.photo-list a {
    opacity: 0;
}

.photo-list a.entry-visible {
    opacity: 1;
}

.photo-list .caption {
    opacity: 0;
    display: block;
    bottom: -20px;
    z-index: 2;

}
.photo-list .caption.caption-visible {
    opacity: 1;
    bottom: 0;
}

.photo-list .caption {
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 40px;
    white-space: pre-wrap;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    z-index: 10;
}

.photo-item-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0;
    z-index: 1;
    font-size: 20px;
    padding: 10px;
    color: white;
    opacity: 0;
    background: rgba(0,0,0,0.6);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 51%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.6)), color-stop(25%, rgba(0,0,0,0)), color-stop(51%, rgba(0,0,0,0)), color-stop(75%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.6)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 51%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 51%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 51%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.6) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 51%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    transition: all 0.2s ease-in-out;
}

.photo-item-overlay-bottom {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0;
    z-index: 1;
    font-size: 20px;
    padding: 10px;
    color: white;
    opacity: 0;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(89,89,89,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(75%, rgba(89,89,89,0)), color-stop(100%, rgba(0,0,0,0.6)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(89,89,89,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(89,89,89,0) 75%, rgba(0,0,0,0.6) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(89,89,89,0) 75%, rgba(0,0,0,0.6) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(89,89,89,0) 75%, rgba(0,0,0,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
    transition: all 0.2s ease-in-out;
}

.img-disabled img,
.img-banned img {
    opacity: 0.4 !important;
}

.img-banned .photo-list-item-ban {
    color: red;
}

.img-disabled .photo-list-item-disable {
    color: rgb(0, 140, 255);
}

.photo-list .jg-entry:hover .photo-item-overlay {
    opacity: 1;
}

.photo-list .jg-entry:hover .photo-item-overlay-bottom {
    opacity: 1;
}

.jg-entry .caption.caption-visible {
    background-color: transparent;
    color: white;
    opacity: 1 !important;
}

.photo-list-item-likes {
    position: absolute;
    top: -10px;
    left: 2px;
    padding: 5px;
    color: white;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.photo-list-item-recommend {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    padding: 5px;
    color: white;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.photo-list-item-ban {
    position: absolute;
    top: -10px;
    left: 2px;
    padding: 5px;
    color: white;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.photo-list-item-disable {
    position: absolute;
    top: -10px;
    right: 2px;
    padding: 5px;
    color: white;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.photo-list .jg-entry:hover .photo-list-item-likes {
    top: 0;
    opacity: 1;
}
.photo-list .jg-entry:hover .photo-list-item-saved {
    top: 0;
    opacity: 1;
}
.photo-list .jg-entry:hover .photo-list-item-ban {
    top: 0;
    opacity: 1;
}
.photo-list .jg-entry:hover .photo-list-item-disable {
    top: 0;
    opacity: 1;
}
.photo-list .jg-entry:hover .photo-list-item-recommend {
    top: 0;
    opacity: 1;
}

.photo-list-item-banned {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    color: red;
    z-index: 100;
    text-align: center;
}

.photo-list-item-banned i {
    font-size: 60px;
}

.photo-list-item-banned p {
    font-size: 12px;
    font-weight: 500;
}

.photo-list-item-disabled {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    color: black;
    z-index: 100;
    text-align: center;
}

.photo-item-blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.photo-item-blur-image {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.photo-item-blur-image-mid {
    filter: blur(10px) !important;
    -webkit-filter: blur(10px) !important;
}

.photo-container.photo-item-blur-image img {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.highlight {
    color: #000;
    background: yellow;
    font-weight: bold;
    padding: 0 2px;
}

.photo-item-adult-overlay {
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    border: 5px solid #ef000a;
    border-radius: 50%;
    padding: 1rem;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-item-adult-overlay.mid {
    font-size: 2.5rem;
    width: 70px;
    height: 70px;
}

.photo-list-item-disabled i {
    font-size: 60px;
}

.photo-list-item-disabled p {
    font-size: 12px;
    font-weight: 500;
}

.photo-list-item-loader {
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    position: absolute;
    color: black;
    z-index: 1000;
}

.photo-list-item-loader i {
   font-size: 50px;
}

.photo-list-item-fade {
    position: absolute;
    pointer-events: none;
    background-color: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;
}

[data-id].loading .photo-list-item-fade {
    display: block;
}

/*
.request-page {

}
*/

.request-item-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.request-title {
    margin-top: -5px;
}

.request-page-details {
    border-top: 1px solid #f1f1f1;
    padding-top: 15px;
    padding-bottom: 10px
}

.request-page-footer {
    border-top: 1px solid #f1f1f1;
    margin-bottom: -10px
}

.comment-list-container {
    overflow: hidden;
    position: relative;

}

.comment-list-gradient {
    opacity: 1;
    background: rgba(241,241,241,1);
    background: -moz-linear-gradient(top, rgba(241,241,241,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(241,241,241,1)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(top, rgba(241,241,241,1) 0%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(top, rgba(241,241,241,1) 0%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(top, rgba(241,241,241,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom, rgba(241,241,241,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f1f1', endColorstr='#ffffff', GradientType=0 );
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    position: absolute;
    z-index: 100;
}

.comment-list {
    padding-top: 50px;
    max-height: 400px;
    overflow-y:scroll;
    position: relative;
    margin-right: 0;
}

.bubble {
    padding: 10px 15px 5px 15px;
    background-color: white;
    margin-bottom: 10px;
    width: 60%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}
.bubble-customer {
    float: right;
}

.bubble-archive {
    background-color: #dedede;
}

.bubble-text {
    white-space: pre-wrap;
}

.request-page textarea {
    resize: none;
    height: 100px;
}



#like.liked .glyphicon {
    color: #dc3545;
}
#save.saved .glyphicon {
    color: #20c997;
}











.text-success {
    color: #20c997;
}

.text-primary {
    color: #007bff;
}

.text-danger {
    color: #dc3545;
}

.tag-row {
    padding-top: 0px;
    display: flex;
    margin-top: 0px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    overflow: hidden;
    position: relative;
}

@media (max-width: 767px) {
    .tag-row {
        display: none;
    }
}

.tag-row .tag-row-item {
    -webkit-box-shadow: inset 0px 3px 1px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: inset 0px 3px 1px -1px rgba(0,0,0,0.15);
    box-shadow: inset 0px 3px 1px -1px rgba(0,0,0,0.15);
    text-align: center;
    flex: 1;
    padding: 3px 10px;
    color: black;
    border-radius: 0px;
    font-size: 12px;
    cursor: pointer;
    background-color: rgba(255,255,255,0.7);
    font-weight: 500;
}
.tag-row .tag-row-item:first-child {
    border-bottom-left-radius: 2px;
}
.tag-row .tag-row-item:last-child {
    border-bottom-right-radius: 2px;
}











.cart-container {
    padding-top: 30px;
}

.cart-item {
    padding: 10px 0;
    position: relative;
    display: flex;
    font-size: 13px
}


#cart_panel .cart-item-title {
    width: 75%
}



.cart-item-img {
    float: left;
    width: 25%;
    padding-bottom: 25%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f1f1f1;
    position: relative
}

.cart-item-img > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    font-size: 50px;
    color: #999;
}

.cart-item-title {
    float: left;
    padding-left: 15px;
}

.cart-item-title select {
    background-color: transparent !important;
    border: none !important;
    font-weight: bold;
}

.cart-item-title select:active,
.cart-item-title select:active:focus,
.cart-item-title select:focus {
    outline: none !important;
}

.cart-item-remove {
    position: absolute;
    top: 10px;
    right: 0;
    color: #dc3545;
    cursor: pointer;
}

.cart-item-checkbox {
    position: absolute;
    bottom: 10px;
    right: 0;
    color: #dc3545;
    cursor: pointer;
}
















.pricing-header {
    text-align: center;
    margin-bottom: 40px;
}

.pricing-packages .panel {
    text-align: center;
    border-radius: 0 !important;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.pricing-packages .panel h3 {
    margin-bottom: 10px;
}

.pricing-packages .panel p {
    margin-bottom: 30px;
}

.panel-heading {
    font-size: 20px;
}

.pricing-packages .panel-heading,
.pricing-packages .panel-body {
    border-radius: 0 !important;
}





.container-fluid.news-welcome {
    padding-top: 40px;
}

/*
.container-fluid.news.results {
    padding-top: 15px;
}
*/

.news-section-title {
    color: #333;
    padding-left: 60px;
    margin-bottom: 15px;
}

.news-carousel {
    padding: 0 30px;
    position: relative;
}


@media (max-width: 767px) {
    .news-carousel {
        padding: 0 0px;

    }
    .news-section-title {
        color: #333;
        padding-left: 10px;
        margin-bottom: 15px;
    }
}

.arrow-left {
    color: #555;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    font-size: 30px;
    cursor: pointer;
}
.arrow-right {
    color: #555;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    font-size: 30px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .arrow-left {
        display:none;
    }
    .arrow-right {
        display:none;
    }
}

.news-item {
    background-color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    margin-bottom: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 30px;
    padding-bottom: 50px;
    position: relative;
    color: black;
    text-decoration: none;
    float: left;
}

.news-item:focus,
.news-item:active,
.news-item:active:focus,
.news-item:hover {
    color: black !important;
    text-decoration: none !important;
}

.news-item h3 {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
}

.news-item h4 {
    margin: 0;
    margin-bottom: 9px;
    padding: 0;
}

.news-item:hover {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
}

.news-item-arrow {
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transition: all 0.2s ease-in-out;
}

.news-item:hover .news-item-arrow {
    background-color: #777;
    color: white;
}

.news-tags {
    margin-bottom: 30px;
}

.news-tags span {
    background-color: #999;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 3px 7px;
    margin-top: -4px;
    font-size: 11px;
}


.pdf object {
    width: 100%;
    min-height: 1000px;
    margin-bottom: 50px;
}

.pdf {
    /*background-color: white;*/
    /*padding-top: 30px;*/
    padding-bottom: 30px;
    margin-bottom: 50px;
}

.pdf img {
    margin: 0 auto;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
}

.news-page-details {
    margin: 0;
    padding: 15px;
}

.news-page-details h3 {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    /*font-family: 'Roboto Slab', serif;*/
}


.news-page-details p {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
}


[name="recommendation"] {
    min-height: 150px;
}

#text-limit {
    margin: 5px 0;
    font-weight: bold;
}

#news_results {
    display: flex;
    flex-wrap: wrap;
}


.news-results-item {
    width: 14.2857143%;
    float: left;
    padding: 0 15px;
    margin-bottom: 25px;
}


@media(max-width: 1400px) {
    .news-results-item {
        width: 16.66666666%;
    }
}

@media(max-width: 1200px) {
    .news-results-item {
        width: 20%;
    }
}

@media(max-width: 1100px) {
    .news-results-item {
        width: 25%;
    }
}

@media(max-width: 900px) {
    .news-results-item {
        width: 33.33333%;
    }
}

@media(max-width: 767px) {
    .news-results-item {
        width: 50%;
    }
}

.news-results-item.recommendation {
    width: 25%;
    position: relative;
}

.news-results-item-inner.init {
    transform: translateY(5px);
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);
    opacity: 0;
}

.news-results-item-inner {
    cursor: pointer;
    /*min-height: 250px;*/
    background-color: white;
    display: block;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);

    position: relative;
    color: black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    opacity: 1;
}

.news-results-item-inner:focus,
.news-results-item-inner:active,
.news-results-item-inner:active:focus,
.news-results-item-inner:hover {
    color: black !important;
    text-decoration: none !important;
}

.news-results-item-inner:visited .news-result-item-tag {
    background-color: #26A69A !important;
    color: white !important;
}


.news-results-item-img {
    width: 100%;
}
.news-results-item-img img {
    width: 100% !important;
}

.news-results-item-img i {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    font-size: 20px;
}

.recommendation-img {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 70%;
    float: left;
    position: relative;
}

.news-results-recommendation-text.v2 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    background-color: rgba(255,255,255,0.9);
    padding:  15px 20px;
    min-height: auto
}

.news-results-recommendation-text {
    padding:  5px 15px;
    display: inline-block;
    min-height: 150px
}

.news-results-recommendation-text h4 {
    font-size: 18px;
    font-weight: bold;
}

.news-results-item-text {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0;
    min-height: 100%;
    background-color: rgba(0,0,0,0.7);
    text-align: left;
    padding: 5px 15px 20px 15px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    color: white;
    z-index: 1;
}

.news-results-item-inner:hover .news-results-item-text {
    visibility: visible;
    opacity: 1;
}

.news-results-item-text h4 {
    font-size: 14px;
    font-weight: bold;
}

.news-result-item-tag {
    position: absolute;
    bottom: 0px;
    left: 0;
    background-color: rgba(255,255,255,0.7);
    padding: 2px 5px;
    font-size: 10px;
    font-weight: bold;
}

.b-carousel-container {
    position: relative
}
.b-carousel {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 120px;
    margin: 10px -15px 0 -15px;
}

.c-arrow-left,
.c-arrow-right {
    /*visibility: hidden;
    opacity: 0;*/
    position: absolute;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    top: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    color: rgb(133, 133, 133);
    text-align: center;
    background-color: white;
    border-radius: 20px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    z-index: 100;
    cursor: pointer;
    transition: all 0.1s ease-in-out
}

.c-arrow-left.visible,
.c-arrow-right.visible {
    visibility: visible;
    opacity: 1;
}

.c-arrow-left:hover,
.c-arrow-right:hover {
    color: black
}

.c-arrow-left {
    left: 5px;
}
.c-arrow-right {
    right: 5px;
}


.b-carousel-inner {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in-out
}

.b-carousel-item {
    width: 70px;
    position: relative;
    float: left;
    margin-right: 5px;
    color: black !important;
    text-decoration: none !important;
}

.img-box {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.b-carousel-item.active {
    opacity: 0.5;
    pointer-events: none;
}

/*
.b-carousel-item.active .b-carousel-index {

}
*/

.b-carousel-index {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    padding: 2px 5px;
    background-color: rgb(68, 68, 68);
    color: white;
    font-weight: bold;
    border-top-left-radius: 5px;
    font-size: 9px
}

.b-carousel-img {
    width: 100%;
}








.welcome-section-title {
    color: #333;
    padding-left: 60px;
    margin-bottom: 15px;
}

.welcome-carousel {
    padding: 0 30px;
    position: relative;
}

.welcome-carousel.loading .welcome-item {
    opacity: 0;
}


.welcome-item {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}

.welcome-item.loading {
    opacity: 0;
}

.welcome-item-popular {
    position: absolute;
    top: -25px;
    left: 25px;
    /*background-color: rgb(255, 232, 0);*/
    background: linear-gradient(-45deg, #fcff9e 0%, #c67700 100%);
    padding: 5px 40px;
    font-weight: bold;
    transform: rotate(-45deg) translate(-50%, -50%);
    text-align: center;

}


@media (max-width: 767px) {
    .welcome-carousel {
        padding: 0 0px;
        position: relative;
    }
}

.welcome-btn-row  {
    margin: 0 -5px;
}

.btn-row-block {
    padding: 0 5px;
}

.welcome-btn.init {
    transform: translateY(5px);
    -ms-transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -o-transform: translateY(5px);
    opacity: 0;
}

.welcome-btn {
    padding: 15px 5px;
    background-color: white;
    text-align: center;
    border-radius: 3px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    cursor: pointer;
    display: block;
    color: black;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    opacity: 1;
}

@media (max-width: 767px) {
    .welcome-btn {
        padding: 10px 5px;
        margin-bottom: 5px
    }
}


.welcome-btn span {
    font-size: 14px;
    line-height: 16px;
}

.welcome-btn b {
    font-size: 16px;
}

.welcome-btn i {
    max-width: 0px;
    overflow: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0;
}

.welcome-btn:hover i {
    max-width: 20px;
    opacity: 1;
}

.welcome-btn:hover,
.welcome-btn:active,
.welcome-btn:focus,
.welcome-btn:focus:active {
    color: black;
    text-decoration: none
}

.arrow-left {
    color: #555;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    font-size: 30px;
    cursor: pointer;
}
.arrow-right {
    color: #555;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    font-size: 30px;
    cursor: pointer;
}



.welcome-item {
    background-color: white;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    margin-bottom: 30px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 30px;
    padding-bottom: 50px;
    position: relative;
    display: block;
    color: black;
    text-decoration: none;

}
.welcome-item:hover {
    color: black;
    text-decoration: none;
}


.welcome-item.custom img {
    max-width: 50%;
    float: right;
    margin-left: 15px;
    margin-bottom: 10px;
    margin-right: 0
}

@media (max-width: 991px) {
    .welcome-item.custom img {
        max-width: 100%;
        float: right;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px
    }
}

.welcome-item-img {
    height: 250px;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 15px;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
}

.news .welcome-item-img {
    background-position: top center;
}

.welcome-item h3 {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-family: 'Roboto Slab', serif;
}

.welcome-item h4 {
    margin: 0;
    margin-bottom: 9px;
    padding: 0;
}

.welcome-item:hover {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.15);
}

.welcome-item-arrow {
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transition: all 0.2s ease-in-out;
}

.welcome-item:hover .welcome-item-arrow {
    background-color: #777;
    color: white;
}

.welcome-tags {
    margin-bottom: 30px;
}

.welcome-tags span {
    background-color: #999;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding: 3px 7px;
    margin-top: -4px;
    font-size: 11px;
}

.welcome-results {
    padding: 0 30px;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .welcome-results {
        padding: 0 0px;
        position: relative;
    }
}

.welcome-results.collapsed {
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0px;
}

.welcome-results .white-container {
    padding: 15px;
}
/*
.welcome-results.collapsed .white-container {
    opacity: 0;
}*/

.welcome-results-img-row {
    max-height: 150px;
    height: 150px;
    overflow: hidden;
    position: relative;
}

.welcome-results-img-row img {
    height: 100%;
    float: left;
    margin-right: 5px;
}

.welcome-results-img-row-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -100%;
}

.welcome-results-tag-row {
    padding-top: 10px;
    margin-bottom: -5px
}

.welcome-results-tag-row .btn {
    margin-bottom: 5px
}

.welcome-results-tag-row .c-arrow-right {
    right: 15px
}


.gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 200px;
    z-index: 10;
    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
}


.result-btn {
    display: block;
    padding: 10px 20px;
    background-color: white;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.result-arrow {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 20px;
    background-color: white;
    width: 50px;
}

.result-arrow i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
}




.alert-heading {
    padding: 15px 0;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
}

.alert-body {
    text-align: center;
}

.payment-logos {
    text-align: center;
    margin-bottom: 10px;
}

.payment-logos img {
    margin: 0 auto 5px auto;
}

.credit-payment,
.payment-info {
    padding-top: 30px;
}

.methods-container {
    padding-top: 50px;
}

.methods-container h2 {
    text-align: center;
}

.method-icon {
    text-align: center;
    font-size: 100px;
    color: #c5c5c5;
}

div.disabled * {
    opacity: 0.5;
    pointer-events: none;
}

.panel {
    position: relative;
}

.panel table {
 font-size: 12px
}

.panel th,
.panel td {
    padding-left: 15px !important;
    padding-right: 15px !important
}

.panel-default {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    border: 0;

}

.payment-finish {
    text-align: center
}

.payment-finish i {
    font-size: 50px;
}

.payment-alert {
    z-index: 10;
    position: absolute;
    max-width: 80%;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
}


.scroll-more {
    display: none;
    margin-bottom: 30px;
}

.bookmarks-container .thumbnail {
    position: relative
}

.bookmark-remove {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    padding: 2px 5px 0 5px;
    z-index: 10;
    cursor: pointer;
    border-bottom-left-radius: 3px
}

.bookmark-remove i {
    transition: all 0.2s ease-in-out;
    opacity: 0.5;
}
.bookmark-remove:hover i {
    opacity: 1;
}

.bookmark-img {
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(223, 223, 223)
}

.bookmark-title {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    white-space: pre-wrap;
    transition: all 0.2s ease-in-out;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
    z-index: 100;
    font-size: 13px;
    padding: 10px 5px 0 5px
}

.thumbnail:hover .bookmark-title {
    opacity: 1;
}

.bookmark-btn-row {
    margin: 0 -9px -9px -9px
}


.bookmark-btn-dropdown li:hover {
    background-color: rgba(0,0,0,0.05)
}

.bookmark-btn-dropdown {
    max-width: 150px
}

.bookmark-btn-dropdown a {
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    padding-right: 35px !important
}

.bookmark-btn-dropdown i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.bookmark-btn-dropdown .fa-plus {
    opacity: 0;
}

.bookmark-btn-dropdown a:hover .fa-plus {
    opacity: 1;
}


#bc_edit {
    text-align: right;
    cursor: pointer;
}

#bc_create {
    text-align: right;
    cursor: pointer;
}

#bc_delete {
    float: right;
}

.bc_copy_clipboard {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    margin: 0 10px 0 10px;
}

.bc_copy_clipboard:active {
    transform: scale(0.70);
}

.copy_clipboard {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    background: #0a0a0a;
    color: #fff;
    font-size: 1.2rem;
    padding: 0 .4rem;
    border-radius: 4px;
    animation: clipboard .3s ease-in forwards;
}

.copy_clipboard.out {
    animation: clipboard-out .3s ease-out forwards;
}

.copy_clipboard::before {
    content: '';
    position: absolute;
    left: 5px;
    top: -2px;
    width: 15px;
    height: 15px;
    z-index: -1;
    background: #0a0a0a;
    transform: rotateZ(45deg);
}

.panel.panel-collection {
    overflow: visible;
}

@keyframes clipboard {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    60% {
        transform: translateY(20px);
        opacity: 1;
    }
    70% {
        transform: translateY(15px);
        opacity: 1;
    }
    100% {
        transform: translateY(20px);
        opacity: 1;
    }
}

@keyframes clipboard-out {
    0% {
        transform: translateY(20px);
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        opacity: 0;
    }
}

@media (max-width: 767px) {

    #bc_edit {
        text-align: left;
        cursor: pointer;
    }


    #bc_delete {
        float: right;
    }

}

.dropdown-menu>li>a.tab {
    padding-left: 35px
}


.faded {
    pointer-events: none;
    opacity: 0.5;
}

.faded2 {
    opacity: 0.5;
    cursor: auto
}

.btn-default.noclick:hover,
.btn-default.noclick:focus,
.btn-default.noclick:focus:active,
.btn-default.noclick:visited,
.btn-default.noclick:active {
    background-color: #f9f9f9;
    border-color: #eee;
    box-shadow: none;
}

.faded-content * {
    pointer-events: none;
    opacity: 0.5;
}

.help-block.small {
    font-size: 11px;
}

.licence table {
    width:100%;
}

.licence td,
.licence th {
    padding: 15px !important
}

.licence td:first-child {
    width: 40%;
}

.licence td:nth-child(4),
.licence td:nth-child(2),
.licence td:nth-child(3) {
    width: 15%;
    vertical-align: middle;
    text-align: center;
}

.licence th:nth-child(4),
.licence th:nth-child(2),
.licence th:nth-child(3) {
    text-align: center;
}

.licence .note {
    font-size: 8px;
    line-height: 14px;
    vertical-align: top;
    font-weight: bold
}

.licence ul {
    margin: 0
}

/* legal list styles */
  .legal {
    padding: 0 20px 15px 20px !important;
    counter-reset: section;
    font-size: 12px
  }
  .legal h5:before {
    counter-increment: section;
    content: counter(section) ". ";
    margin: 0 0.5em 0 0;
  }
  .legal ol {
    counter-reset: clause;
    list-style: none outside none;
    text-indent: -2em;
  }
  .legal ol > li {
    counter-increment: clause;
    margin-bottom: 10px
  }
  .legal ol > li:before {
    content: counter(section) "." counters(clause, ".") ". ";
    margin: 0 0.5em 0 0.5em;
    font-weight: bold
  }

  .legal ul > li {
      text-indent: 0;
      margin-bottom: 5px
  }


#article_delete {
    float: right
}

.thumbnail .caption {
    text-overflow: ellipsis;
    overflow: hidden;
}

.thumbnail .caption p {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
}

.thumbnail .caption b {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
}

#cart_panel_sum span, #cart_panel_balance span {
    float: right;
    font-weight: bold
}

#cart_panel {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden
}








.embed-body {
    background-color: black;
}

.embed-container2 {
    position: relative;
}

.embed-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 2;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.embed-container2:hover .embed-info-overlay {
    opacity: 1;
}

.embed-picture {
    display: flex;
    justify-content: center;
}

.embed-info-inner {
    min-height: 100%;
    width: 100%;
    padding: 5%;
    color: white;
    text-decoration: none;
}

.embed-info-inner a {
    color: white;
    text-decoration: underline
}

#embedCollapse textarea {
    min-height: 150px;
}

























/* admin stuff */

    .dropdown-header {
        font-size: 9px
    }

  #chart {
      margin-bottom: 100px;
  }

  .sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  /*
   * Top navigation
   * Hide default border to remove 1px line.
   */
  .navbar-fixed-top {
    border: 0;
  }

  /*
   * Sidebar
   */

  /* Hide for mobile, show later */
  .sidebar {
    display: none;
  }
  @media (min-width: 768px) {
    .sidebar {
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0;
      z-index: 0;
      display: block;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
      border-right: 1px solid #ddd;
    }
  }

  /* Sidebar navigation */
  .nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
  }
  .nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-sidebar > .active > a,
  .nav-sidebar > .active > a:hover,
  .nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
  }

  .main {
    padding: 20px;
  }
  @media (min-width: 768px) {
    .main {
      padding-right: 40px;
      padding-left: 40px;
    }
  }
  .main .page-header {
    margin-top: 0;
  }

.table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>thead>tr>td.active, .table>thead>tr>th.active {
    background-color: #ffffff;
}
.table>tbody>tr.canvas>td, .table>tbody>tr.canvas>th, .table>tbody>tr>td.canvas, .table>tbody>tr>th.canvas, .table>tfoot>tr.canvas>td, .table>tfoot>tr.canvas>th, .table>tfoot>tr>td.canvas, .table>tfoot>tr>th.canvas, .table>thead>tr.canvas>td, .table>thead>tr.canvas>th, .table>thead>tr>td.canvas, .table>thead>tr>th.canvas {
    background-color: rgb(225, 249, 255);
}

tr.biz>td {
    background-color: #e3fff1 !important;
}

.analytics-row {
    padding: 5px 0;
    border-bottom: 1px solid #f1f1f1;
}

.analytics-row.active {
    background-color: white;
}
.analytics-row.canvas {
    background-color: rgb(225, 249, 255);
}
.analytics-row.paper {
    background-color: rgb(255, 225, 225);
}
.analytics-row.webshop {
    background-color: rgb(241, 245, 189);
}
.analytics-row.paper.canvas {
    background-color: rgb(225, 255, 232);
}

.analytics-list-head {
    padding: 5px 0;
    font-weight: bold;
}

.analytics-row [data-toggle] {
    cursor: pointer;
    font-weight: bold;
}

.image-sums img {
    max-width: 100px !important;
    max-height: 100px !important;
}



.second {
    padding-left: 30px;

}

.third {
    padding-left: 60px;
}

.fourth {
    padding-left: 90px;
}

.tree-main {
    border-left: 2px solid rgb(196, 196, 196);
    padding: 0 15px;
    margin-bottom: 45px
}

.tree-block {
    margin-bottom: 15px
}



.thumbnail {
    padding: 0;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    overflow: hidden;
    border: 0;
    text-decoration: none;
    color: black;
    position: relative;
}

.thumbnail:active,
.thumbnail:visited,
.thumbnail:focus,
.thumbnail:focus:active,
.thumbnail:hover {
    border: 0;
    text-decoration: none;
    color: black;
}




.disabled {
    pointer-events: none;
}


.radio.discount b {
    text-decoration: line-through;
}

.radio.discount .size-price {
    text-decoration: line-through;
}

.discount-box {
    padding: 5px 10px;
    text-align: center;
    color: white;
    background-color: #26A69A;
    margin-bottom: 10px;
}



.stories-list {
    margin-bottom: 50px
}

.story-list-img {
    margin: -15px 0 -15px -15px;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .story-list-img {
        margin: -15px -15px 15px -15px;
        padding-bottom: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.story-content {
    /*font-family: 'Roboto Slab', serif;*/
    background-color: white;
}

.story-editor {
    min-height: 100vh;
}

.story-head {
    position: relative;
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.story-head-img {
    position: absolute;
    z-index: 0;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

@media(max-width: 991px) {
    .story-head {
        height: 500px;
    }
}
@media(max-width: 767px) {
    .story-head {
        height: 300px;
    }
}

.story-head-btns-bottom {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 10000
}

.story-head-btns-top {
    position: absolute;
    top: 15px;
    left: 15px
}

.story-head.top {
    background-position: top center !important;
}
.story-head.center {
    background-position: center center !important;
}
.story-head.bottom {
    background-position: bottom center !important;
}

.story-logo {
    position: absolute;
    color: white !important;
    top: 5px;
    left: 5px
}

.story-logo a {
    color: white !important;
}

.story-title {
    padding-top: 30px
}

.story-title h1 {
    font-size: 40px;
    margin-bottom: 30px;
    font-family: 'Roboto Slab', serif;
}

.story-title h4 {
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 1.42857143;
}

.story-title h4 a{
    text-decoration: underline;
}


.story-body {
    /*color: white;*/
    padding: 50px 0;
}



.story-body a {
    text-decoration: underline;
}


.story-body .img-text {
    font-size: 11px;
    color: #777777;
    padding: 5px 0;
}

/*
.story-body iframe {

}
*/


.story-body p {
    margin-bottom: 30px
}

.story-editor .story-body p {
    margin-bottom: 30px
}

.ck-editor__main p {
    margin-bottom: 30px;
}

p.drop-caps:first-letter {
    color: #903;
    float: left;
    font-family: 'Roboto Slab', serif;
    font-size: 55px;
    line-height: 42px;
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 3px;
}

blockquote {
    font-style: italic;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 50px;
}
blockquote p {
    font-size: 15px
}

.stories {
    padding-bottom: 100px;
}


#uploads_list {
    max-height: 700px;
    overflow-y: scroll;
}

#uploads_list .panel {
    box-shadow: none
}

.uploads-list-row {
    width: 100%;
    float: left;
    margin-bottom: 5px
}

.uploads-list-img {
    float: left;
    width: 25%;
}

.uploads-list-url {
    float: left;
    width: 75%;
    padding: 0 5px;
    word-break: break-word;
}

#normal_image_upload input[type='file'],
#big_image_upload input[type='file'] {
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    display: inline-block;
    float: left;
}


.panel {
    overflow: hidden;
}

.panel-img {
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: -15px -15px 15px -15px;
}

.embed-container {
    position: relative;
    padding-top: 40%;
    height: 0;
    overflow: hidden;
    max-width: 60%;
    margin: auto;
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.comments {
    margin-bottom: 150px
}


/* temp stuff for vod */









.player-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000;
    display: none;
}

#m3_player {
    background-color: black;
    overflow: hidden;
}

#m3_player.dev {
    padding-bottom: 75%;
    background-color: #26A69A !important;
}

#m3_player.error,
#m3_player.not-allowed,
#m3_player.not-playable {
    padding-bottom: 75%;
    position: relative;
}

.error-box,
.not-playable-box,
.not-allowed-box {
    font-family: 'Quicksand', sans-serif !important;
    letter-spacing: 0.7px !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    transform: translate(0, -50%);
    color: white;
    padding: 15px;
    text-align: center;
}

.not-playable-box a,
.not-allowed-box a {
    color: white;
    text-decoration: underline;
}

.not-playable-box span,
.not-allowed-box span {
   font-size: 11px;
   display: block;
   margin: 10px 0
}

.not-playable-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
}

.vimeo-container {
    width: 100%;
    padding-bottom: 75%;
    position: relative;
}

#vimeo_player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#intro_player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    max-height: calc(100vh - 80px);
}

#intro_player video {
    /*position: absolute;*/
    width: 100%;
    height: 100%;
}





.m3-content {
    background-color: rgb(10,10,10);
    padding-top: 30px;
    padding-bottom: 100px
}

.vod-content {
    background-color: rgb(10,10,10);
    padding-top: 30px;
    padding-bottom: 100px
}

.m3-hero {
    padding: 70px 30px;
    margin-top: -30px;
    color: white;
    position: relative;
    overflow: hidden;
}

@media (max-width: 767px) {
    .m3-hero {
        padding: 30px 0px 60px 0px;
    }

}



.public-hero {
    margin-top: -30px;
    color: white;
    position: relative;
    overflow: hidden;
}

.hero-col {
    height: calc(100vh - 80px);
    position: relative;
}

@media (max-width: 991px) {
    .hero-col {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
    }
}

.hero-player-container {
    position: relative;
    padding-bottom: 75%;
    z-index: 10;
}

.hero-player-container-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hero-player {
    position: relative;
    padding-bottom: 75%;
    z-index: 10;
    transition: all 0.3s ease-in-out
}

.hero-player.wide,
.hero-player-container.wide {
    padding-bottom: 56.25%;
}

.hero-player-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}

.hero-intro-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 2;
}

.hero-intro-player .play {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 50px;
    z-index:3;
    color: white;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}
.hero-intro-player .play.invisible {
    opacity: 0;
}



.hero-intro-player video {
    width: 100%;
    height: 100%;
}

.hero-player-buttons {
    position: absolute;
    /*transform: translate(-50%,-50%);*/
    text-align: center;
    opacity: 0;
    z-index: 1000;
    transition: all 0.2s ease-in-out;
    top: 10px;
    right: 10px;
}

.hero-player-buttons .btn {
    font-weight: bold;
}

.hero-player-buttons .btn.active {
    opacity: 0.8;
    pointer-events: none;
}

.hero-player:hover .hero-player-buttons {
    opacity: 1;
}

.hero-player-modal {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display:none;
    z-index: 100;
    max-width: 50%;
    width: 100%
}

.hero-player-modal.toggled {
    display: block;
}

.hero-player-modal-inner {
    padding: 15px;
    border-radius: 3px;
    background-color: black;
    max-width: 500px;
    width: 100%
}

.hero-player-modal-inner textarea {
    resize: none;
    height: 150px;
}

.hero-player-modal-close {
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 20px;
    cursor: pointer
}

.hero-col .hero-player {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 10%;
    right: 10%;
}


.hero-col .hero-text {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0
}

/*
.hero-text h5 {

}
*/

@media (max-width: 991px) {
    .hero-col .hero-text {
        position: relative;
        transform: translateY(0);
        top: 0;
        left: 0;
        right: 0;
        padding: 0 15px
    }
    .hero-col .hero-player {
        position: relative;
        transform: translateY(0);
        top: 0;
        left: 0;
        right: 0;
    }

}




.hero-bg {
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    filter: blur(10px);
    -webkit-filter:blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    opacity: 0.3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
    transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.hero-bg.switch {
    opacity: 0;
}


.m3-hero.down .hero-container {
    opacity: 0.2;
    filter: blur(15px);
    -webkit-filter:blur(15px); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
}

.m3-hero.down .hero-bg {
    opacity: 0;
}

.hero-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: -moz-linear-gradient(top, rgba(10,10,10,0) 0%, rgba(10,10,10,0) 50%, rgba(10,10,10,1) 100%);
    background: -webkit-linear-gradient(top, rgba(10,10,10,0) 0%,rgba(10,10,10,0) 50%,rgba(10,10,10,1) 100%);
    background: linear-gradient(to bottom, rgba(10,10,10,0) 0%,rgba(10,10,10,0) 50%,rgba(10,10,10,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001b1b1b', endColorstr='#1b1b1b',GradientType=0 );
}

@media (min-width: 1200px) {
    .m3-hero .container {
        width: 90vw;
    }
}

.m3-hero .hero-text h5 {
    font-size: 12px
}

.m3-hero .hero-text {
    position: relative;
    /*overflow: auto;*/
}

.hero-container,
.active-container {
    position: relative;
}


.more-info {
    cursor: pointer;
    text-decoration: underline
}

.active-extended-info-modal {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0;
    /*background-color: rgba(0,0,0,0.7);*/
    z-index: 1000;
    transition: all 0.2s ease-in-out;
}

.active-extended-info-modal h1 {
    margin-top: 0;
    margin-bottom: 20px
}

.active-extended-info-modal-inner {
    padding: 100px 0;
    /*margin-right: -17px;*/
    height: 100%;
    overflow: auto;
    position: relative;
}

.active-extended-info-modal-inner .container{
    position: relative;
}

.active-extended-modal-close {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 1;
    color: white;
    cursor: pointer;
    font-size: 30px
}

.active-extended-info-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 16px;
    height: 100px;
    background: rgba(10,10,10,0);
    background: -moz-linear-gradient(top, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(10,10,10,0)), color-stop(100%, rgba(10,10,10,1)));
    background: -webkit-linear-gradient(top, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 100%);
    background: -o-linear-gradient(top, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 100%);
    background: -ms-linear-gradient(top, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 100%);
    background: linear-gradient(to bottom, rgba(10,10,10,0) 0%, rgba(10,10,10,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );

}

.active-extended-info-modal.toggled {
    opacity: 1;
    visibility: visible;
}

.blurred {
    filter: blur(15px);
    -webkit-filter:blur(15px); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
}

.live-info {
    color: white;
    cursor: pointer;
    font-weight: bold;
    width: fit-content;
}

.active-help {
    margin-bottom: 0px;
    margin-top: 30px
}

.active-title {
    margin-top: 10px;
    font-size: 24px
}

.active-subtitle {
    font-size: 14px;
    margin-bottom: 10px
}

.active-info-line {
    margin-bottom: 30px;
    font-size: 11px;
}

.active-extended-info-modal .active-subtitle {
    font-size: 16px;
    margin-bottom: 15px
}

.active-extended-info-modal .active-production {
    margin-bottom: 30px
}

.active-extended-info-modal .active-full-description {
    margin-bottom: 30px
}

/*
.active-description {
}

.active-contributors {

}

.active-creators {

}
*/

.reactions {
    padding: 15px 0;
    position: relative;
}

.reaction-alert {
    /*position: absolute;*/
    top: 100%;
    left: 0;
}

.reaction {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    min-width: 100px;
    text-align: center;
    border-radius: 3px;
    background-color: rgba(255,255,255,0.7);
    color: black;
    font-weight: bold
}

.reactions.not-allowed .reaction:hover {
    background-color: rgba(255,255,255,0.7) !important;
}

.reaction.like:hover,
.reaction.like.active {
    background-color: #20c997
}

.reaction.dislike:hover,
.reaction.dislike.active {
    background-color: #dc3545
}

.reaction-count {
    font-size: 12px
}

.jwplayer.jw-skin-m3 .jw-logo {
    width: 50px !important;
    height: 50px !important;
}

@media (max-width: 767px) {
    .jwplayer.jw-skin-m3 .jw-logo {
        width: 30px !important;
        height: 30px !important;
    }
}




.show {
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 56.25%;
    background-size: 105%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: black;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgb(66, 66, 66);
}
@media(max-width: 767px) {
    .show {
        background-size: cover;
    }
}

.show-pg {
    position: absolute;
    bottom: 10px;
    right: 7px;
    background-color: black;
    border-radius: 20px;
    padding: 2px;
    z-index: 2;
    transition: all 0.2s ease-in-out;
}

.active-pg {
    display: inline-block;
    background-color: black;
    border-radius: 20px;
    padding: 2px;
    margin-bottom: -10px
}

.guide-pg {
    display: inline-block;
    border-radius: 0;
    margin-bottom: 0
}

.pg {
    display: block;
    border: 2px solid;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 21px;
    border-radius: 20px;
    font-size:10px;
    font-weight: bold;
    border-color: rgb(124, 191, 52);
    color: rgb(124, 191, 52)
}

.active-pg .pg {
    line-height: 21px
}

.guide-pg .pg {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    line-height: 18px;
    border-width: 1px;
    font-size: 9px;
    text-align: center;
    transform: translateY(-4px)
}

.pg.pg-no-pg {
    border-color: rgb(124, 191, 52);
    color: rgb(124, 191, 52)
}

.pg.pg-6 {
    border-color: rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}
.pg.pg-12 {
    border-color: rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}
.pg.pg-16 {
    border-color: rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}
.pg.pg-18 {
    border-color: rgb(229, 1, 28);
    color: rgb(229, 1, 28)
}

.show-item {
    box-sizing: border-box;
    cursor: pointer;
    background-color: black;
    border-radius: 5px;
    position: relative;
    float: left;
    height: 200px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 2px;
    transition: all 0.2s ease-in-out;
    overflow: hidden
}

.show-item.active .show {
    border: 3px solid white;;
}

.show-item.inactive .show {
    opacity: 0.6;
}

.show-player {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.show-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 30px;
    z-index: 2;
    padding: 15px;
    color: white;
    transition: all 0.2s ease-in-out
}

@media (max-width: 767px) {
    .show-title h5 {
        font-size: 10px
    }
    .show-title {
        padding: 10px
    }
}

.show-icons {
    position: absolute;
    top: 8px;
    right: 10px;
    color: white;
}

.show-icon {
    padding: 1px 4px 0px 4px;
    display: inline-block;
    color: rgb(54, 54, 54);
    background-color: white;
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
    font-family: 'Raleway', sans-serif;
}

.resolution-icon {
    font-style: italic;
    background: rgb(131, 116, 47);  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #D3CBB8, rgb(131, 116, 47));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #D3CBB8, rgb(131, 116, 47)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.guide-icon.show-icon {
    font-size: 9px;
    transform: translateY(-3px);
}

.active-cc {
    display: inline-block;
}

.active-cc i {
    font-size: 20px;
    margin-bottom: -10px
}

.show-title h5 {
    margin: 0;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.show-title .subtitle {
    margin: 2px 0 0 0;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.show-play {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: white;
    font-size: 30px;
    z-index: 3;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.show:hover .show-play {
    transform: translate(-50%, -50%) scale(1.3,1.3);
}

.live-time {
    display: block;
    text-align: right;
    margin-bottom: 3px;
    margin-top: -20px
}

.status-line {
    position: relative;
    background-color: #727272;
    height: 3px;
    margin-bottom: 30px;
    overflow: hidden;
}

.status-line .line {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: white;
    transition: width 0.2s ease-in-out;
}


.show-gradient {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: 1;
}

.show-row {
    margin: -50px -15px 50px -15px;
    position: relative;
    height: 300px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.show-row-arrow-left,
.show-row-arrow-right {
    position: absolute;
    height: 200px;
    width: 100px;
    cursor: pointer;
    z-index: 100;
    color: white;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}


@media(max-width: 767px) {
    .show-row-arrow-left,
    .show-row-arrow-right {
        width: 50px;
    }
}

.show-row-arrow-left {
    left: 0;
}
.show-row-arrow-right {
    right: 0;
}

.show-row-arrow-left i,
.show-row-arrow-right i {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 20px
}

.show-row-arrow-left {
    left: 0;
    background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(125,185,232,0) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%,rgba(125,185,232,0) 100%);
    background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#007db9e8',GradientType=1 );
}
.show-row-arrow-right {
    right: 0;
    background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
}

.show-row-inner {
    position: absolute;
    left: 0px;
    top: 50px;
    bottom: 50px;
    transition: left 0.5s ease-in-out;
    padding-right: 100px;
    padding-left: 100px;
}

.empty .show-row-inner {
    background-color: rgba(0,0,0,0.1)
}


@media (max-width: 767px) {
    .show-row-inner {
        padding-right: 50px;
        padding-left: 50px;
    }
}

.show-item .show {
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.show-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 110%;
    background-position: center;
    background-repeat: no-repeat;
}

@media(max-width: 991px) {
    .show-bg {
        background-size: cover;
    }
}

#daily_program_container {
    margin-top: 0px;
    margin-bottom: 50px
}

.dp-carousel-container {
    margin-bottom: 50px
}

.dp-carousel {
    padding: 0 5vw;
    margin: 0 -15px;
}


.dp-carousel.loading .dp-item  {
    display:none;
}

.dp-carousel-loader {
    opacity: 0.5;
    font-size: 30px;
    padding: 20px
}

@keyframes loading {
    0% {opacity: 100%;}
    50% {opacity: 30%;}
    100% {opacity: 100%;}
}

.dp-carousel-title {
    padding: 0px calc(5vw + 5px) 10px calc(5vw + 5px);
    font-weight: bold
}

.dp-carousel-title a {
    color: white !important;
}

.dp-carousel-title a:hover {
    color: white !important;
}

.dp-carousel .slick-track {
    margin-left: 0;
    margin-right: 0;
}

.dp-item {
    padding: 0 5px;
    outline: none !important
}

.dp-item-inner {
    position: relative;
    padding-bottom: 65%;
    background-size: 110%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgb(66, 66, 66);
    box-sizing: border-box
}

.dp-item-inner.ranked {
    border: 2px solid #d2942280;

}

.radio-item .dp-item-inner {
    padding-bottom: 100%;
    background-size: cover;
}

/*
.radio-item .show-title h5 {
    margin: 0;
    margin-bottom: 5px;
    line-height: 1.1;
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
    font-size: 23px;
}*/

.radio-item .show-bg {
    background-size: cover;
}

.uhd-item .dp-item-inner {
    padding-bottom: 130%;
    background-size: cover;
}

.uhd-item .show-bg {
    background-size: cover;
}

.dp-item.toggled .dp-item-inner {
    border: 1px solid white;
}

.series-toggle-arrow {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 100%;
    height: 15%;
    transition: all 0.2s ease-in-out;
    color: white;
    z-index: 10;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
}

.series-toggle-arrow i {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;

}

.dp-series-item:hover .series-toggle-arrow {
    top: 85%;
}

.dp-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5vw;
    color: white;
    font-size: 20px;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dp-arrow:hover {
    background-color: rgba(255,255,255,0.05);
}

.dp-arrow i {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    transition: all 0.2s ease-in-out;
}

.dp-arrow.prev {
    left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dp-arrow.next {
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.dp-arrow.prev:hover i {
    left: 45%;
}
.dp-arrow.next:hover i {
    left: 55%;
}

@media(max-width: 991px) {
    .dp-carousel {
        padding: 0 30px;
    }

    .dp-carousel-title {
        padding: 0 35px;
    }

    .dp-item-inner {
        padding-bottom: 130%;
        background-size: cover
    }

    .dp-arrow {
        width: 30px
    }
}
@media(max-width: 600px) {
    .dp-carousel {
        padding: 0 10px;
    }

    .dp-carousel-title {
        padding: 0 15px;
    }

    .dp-item-inner {
        padding-bottom: 130%;
        background-size: cover
    }

    .dp-arrow {
        width: 30px
    }
}



.series-collapse-inner {
    padding: 0 5vw 25px 5vw;
}

@media(max-width: 991px) {
    .series-collapse-inner {
        padding: 0  35px 25px 35px;
    }
}
@media(max-width: 600px) {
    .series-collapse-inner {
        padding: 0  10px 25px 10px;
    }
}

.series-details-close {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 25px;
    cursor: pointer;
}

.series-details {
    position: relative;
    color: white
}

.series-title h3 {
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.episode-list {
    min-height: 150px;
    margin: 0 -10px;
    position: relative;
}

.episode-list-item {
    padding: 5px 10px;
    color: white;
    cursor: pointer;
}

.episode-list-item:hover {
    background-color: rgba(255,255,255,0.1)
}

.episode-list-item i {
    margin-right: 5px
}

.m3-feedback-container {
    padding-top: 100px;
}

.m3-feedback-container p {
    color: #dedede;
    margin-bottom: 20px
}

.m3-public {
    background-color: black;
    position: relative;
    z-index: -1;
}

.public-player {
    z-index: 0;
    position: absolute;
    min-width: 500px;
    width: 100%;
    max-width: 800px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    overflow: hidden;
}

.m3-welcome-title {
    padding: 20px 0;
    color: white;
    text-align: center;
}

.daily-program-container {
    margin-bottom: 50px;
    position: relative;
}

.daily-program-container .show-item {
    width: 100%;
    padding-bottom: 60%;
    margin-bottom: 30px;
    height: auto;
}

.daily-program-container .show {
    border: none;
}

.daily-program-container .show-item.selected .show {
    border: 2px solid white
}

.daily-program-container .show-play {
    font-size: 18px
}

@media (min-width: 1300px) {
    .col-xl-2 {
        width: 16.66666666%;
    }
}

.daily-program {
    max-height: 500px;
    overflow: hidden;
}

.daily-program-fade {
    position: absolute;
    background: rgb(10,10,10);
    background: linear-gradient(0deg, rgba(10,10,10,1) 0%, rgba(10,10,10,0) 100%);
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 10;
}

.program-guide {
    color: rgb(218, 218, 218);
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.program-guide.embed {
    height: 85vh;
}

.program-guide.loading .guide-row {
    opacity: 0.4;
}

.program-guide-date {
    font-size: 20px;
    text-align: center;
    padding: 15px 0;
    color: white;
    font-weight: bold;
    position: relative;
}

.program-guide-date .pg-date {
    padding: 0 15px
}

.program-guide-date .prev,
.program-guide-date .next {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    display: none;
    cursor: pointer;
}

.program-guide-date .prev {
    left: 5px;
}
.program-guide-date .next {
    right: 5px
}

@media(max-width: 767px) {
    .program-guide-date .prev,
    .program-guide-date .next {
        display: inline-block;
    }
}

.guide-arrow-container {
    height: 500px;
    position: relative;
}

.guide-arrow-container.embed {
    height: 85vh;
}

@media(max-width: 767px) {
    .guide-arrow-container {
        display: none
    }
}

.guide-arrow {
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    bottom: 0;
    color: white;
    font-size: 40px;
    cursor: pointer
}

.guide-arrow.next {
    background: rgba(255,255,255,0);
}

.guide-arrow.prev {
    background: rgba(255,255,255,0);
}

.guide-arrow.next:hover {
    background: rgba(255,255,255,0.03);
}

.guide-arrow.prev:hover {
    background: rgba(255,255,255,0.03);
}

.guide-arrow i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
}

.guide-arrow.prev:hover i {
    left: 45%;
}
.guide-arrow.next:hover i {
    left: 55%;
}

.guide-row {
    display: flex;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    opacity: 1;
}

[data-id] .guide-titles {
    cursor: pointer;
    position: relative;
}

.guide-titles .play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: -25px;
    font-size: 20px;
    color: rgba(255,255,255,0.7);
}

@media(max-width: 767px) {
    .guide-titles .play-btn {
        left: -10px;
    }
}

.guide-row.bordered {
    border-top: 1px solid rgba(255,255,255,0.2)
}

.guide-time {
    padding: 10px 15px;
    color: rgba(255,255,255,0.5);
    font-size: 14px;
    float: left;
    width: 25%;
}

@media(max-width: 767px) {
    .guide-time {
        font-size: 12px
    }
}

.guide-time.transparent {
    background-color: transparent
}

.guide-titles {
    padding: 10px 15px;
    float: left;
    width: 75%;
}

.guide-row:hover .guide-titles {
    background-color: rgba(255,255,255,0.2)
}
.guide-row:hover .guide-time {
    background-color: rgba(255,255,255,0.1)
}

@media(max-width: 767px) {
    .guide-row:hover .guide-time {
        background-color: rgba(255,255,255,0.2)
    }
}

.guide-titles .titles {
    float: left;
    width: 70%;
}
.guide-titles .icons {
    float: left;
    width: 30%;
}

.guide-titles .icons i {
    font-size: 18px;
    margin: 0 3px
}

.guide-titles h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.guide-titles h6 {
    font-size: 10px;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(255,255,255,0.5)
}

.ps__rail-y  {
    opacity: 0.9 !important;
}


.embed-player-container {
    position: relative;
    min-height: 100vh;
}

.embed-player {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.embed-message {
    position: absolute;
    z-index: 10;
    color: white;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    opacity: 0;
    left: 0;
    right: 0;
    padding: 30px;
    transition: all 0.5s ease-in-out;
    text-align: center;
}

.embed-message a {
    font-weight: bold;
    color: white;
    text-decoration: underline;
}

.embed-player-container:hover .embed-message {
    opacity: 1;
}
.open-embed-player-container:hover .embed-message {
    opacity: 1;
}

.jwplayer.jw-skin-m3embed .jw-logo {
    width: 50px !important;
    height: 50px !important;
}

@media (max-width: 767px) {
    .jwplayer.jw-skin-m3embed .jw-logo {
        width: 30px !important;
        height: 30px !important;
    }
}

.jwplayer.jw-skin-m3embed .jw-aspect {
    padding-top: 0 !important;
    height: 100vh;
    max-height: 100vh;
}

.bookmark-btn {
    display: inline-block
}

/* temp stuff for radio */

.radio-content {
    background-color: rgb(19, 19, 19);
    padding-bottom: 50px
}

.radio-player {
    position: fixed;
    background-color: black;
    padding: 0px 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    z-index: 1000;
    color: white;
}

.player-title {
    line-height: 40px
}

.audio-row {
    margin: 0 -10px;
    margin-bottom: 15px;
}

.audio-item {
    float: left;
    width: 16.666666%;
    position: relative;
    padding: 10px
}

.audio-item-bg {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255,255,255,0.02)
}

.audio-item-title {
    color: white;
    padding: 5px 0;
    font-size: 12px
}

.audio-item-play {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    font-size: 40px;
}

.audio-item:hover .audio-item-play {
    opacity: 1;
}

.audio-item-wave {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;

}

.audio-item-wave-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.audio-item-wave-line {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 3px;
    background-color: rgba(255,255,255,0.3);
}

.artist-card {
    background-color: rgba(255,255,255,0.1);
    padding: 15px;
    border-radius: 3px;
    color: white;
    cursor: pointer
}

.artist-card-avatar {
    width: 100px;
    position: relative;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(255,255,255,0.1);
    float: left;
}

.artist-avatar-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-repeat: no-repeat;
}

.artist-card-avatar i {
    position: absolute;
    transform: translate(-50%,-50%);
    font-size: 50px;
    color: rgba(255,255,255,0.1);
    top: 50%;
    left: 50%;
}

.artist-card-details {
    width: calc(100% - 100px);
    float: left;
    padding-left: 15px;
}

.more-results {
    color: white;
    position: relative;
    width: 100%;
    height: 130px;
}

.more-results div {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
}


.results-record {
    color: white;
    position: relative;
    height: 100px;
    background-color: rgba(255,255,255,0.03);
    margin-bottom: 15px;
    cursor: pointer
}

.record-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100px;
    background-color: rgba(255,255,255,0.02);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.record-img-play {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    font-size: 25px;
}

.results-record:hover .record-img-play {
    opacity: 1;
}

.item-record:hover .record-img-play {
    opacity: 1;
}

.record-title {
    position: absolute;
    top: 10px;
    left: 115px;
}

.record-duration {
    position: absolute;
    top: 10px;
    right: 15px;
}

.record-wave {
    position: absolute;
    left: 115px;
    top: 0;
    right: 0;
    bottom: 0;
}

.record-wave-inner {
    position: relative;
    width: 100%;
    height: 100%;
}

.progress-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    background-color: rgba(0,251,255,0.05);
    width: 0%;
    z-index: 0;
    transition: all 0.2s ease-in-out;
}

.record-wave-inner .wave {
    position: absolute;
    width: 0.5%;
    transform: translateY(-50%);
    background-color: rgba(255,255,255,0.13);
    top: 50%;
    z-index: 1;
}

.vinyl {
    position: absolute;
    width: 90%;
    background-color: rgba(255,255,255,0.03);
    border-radius: 50%;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    z-index: 0;
    height: 90%
}

.vinyl-hole {
    position: absolute;
    transform: translate(-50%,-50%);
    background-color: rgba(0,0,0,0.5);
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    border-radius: 50%
}

.record-img .vinyl-hole {
    width: 15px;
    height: 15px;
}

.record-item-panel {
    background-color: rgba(255,255,255,0.1);
    padding: 15px;
    border-radius: 3px;
    color: white;
}

.item-record {
    color: white;
    position: relative;
    height: 150px;
    margin-bottom: 15px;
    cursor: pointer
}

.item-record .record-img {
    width: 150px;
}

.item-record .record-wave {
    left: 165px;
}

.item-record .record-duration {
    right: 0;
}

/* temp stuff for radio */




  .compare-wrapper {
    width: 900px;
    height: 600px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  /* Our image information */
  .compare-before,
  .compare-after {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .content-image {
    height: 100%;
  }

  .compare-after {
    width: 125px;
  }

  .compare-scroller {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: transparent;
    opacity: 0.9;
    pointer-events: auto;
    cursor: pointer;
  }

  .compare-scroller:hover {
    opacity: 1;
  }

  .compare-scrolling {
    pointer-events: none;
    opacity: 1;
    z-index: 1;
  }

  .compare-scroller__thumb {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .compare-scroller:before,
  .compare-scroller:after {
    content: " ";
    display: block;
    width: 7px;
    height: 9999px;
    position: absolute;
    left: 50%;
    margin-left: -3.5px;
    z-index: 30;
    transition: 0.1s;
  }

  .compare-scroller:before {
    top: 100%;
  }

  .compare-scroller:after {
    bottom: 100%;
  }

  /* If you want to cahnge the colors, make sure you change the fill in the svgs to match */
  .compare-scroller {
    border: 5px solid #fff;
  }

  .compare-scroller:before,
  .compare-scroller:after {
    background: #fff;
  }

  #code_list_pagination span {
      cursor: pointer;
      padding: 0 3px;
  }

  .promo-codes-admin [data-sort] {
      cursor: pointer;
  }

  .promo-codes-admin [data-sort].active {
      font-weight: bold;
  }

  .promo-codes-admin .promo-code-row {
      cursor: pointer;
  }
  .promo-codes-admin .promo-code-row:hover {
      background-color: #ececec
  }

  .alert a {
      color: inherit;
      font-weight: bold;
      text-decoration: underline;
  }

  .quiz, .sound-quiz {
      position: relative;
  }

  .quiz, .sound-quiz audio {
    width:100%;
  }

  .sound-quiz .list-group-item-danger:hover {
    background-color: #f2dede !important;
  }
  .sound-quiz .list-group-item-success:hover {
    background-color: #dff0d8 !important;
  }

  .quiz, .sound-quiz .panel {
      max-width: 650px;
      margin: 2rem auto
  }

  .quiz, .sound-quiz .panel {
    margin-bottom: 50px
  }

  .quiz, .sound-quiz .list-group {
      margin-bottom: 0;
  }

  .quiz, .sound-quiz .list-group-item {
      cursor: pointer;
      font-weight: bold;
  }

  .quiz, .sound-quiz .list-group-item:hover {
      background-color: rgba(0,0,0,0.03);
  }

  .list-group-item:not(.selected):hover {
    background-color: rgba(0,0,0,0.3);
    color: white;
  }

  .quiz-img {
      padding-bottom: 75%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: black;
      border-radius: 5px;
  }

  .quiz-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 50px;
  }



  span[data-page] {
    cursor: pointer;
    padding: 0px 3px
  }

  span[data-page].active {
    font-weight: bold;
  }

/*
.bm-embed {
}
*/

.bm-gallery {
    position: relative;
    height: 600px;
}

.bm-gallery a {
    color: white;
    text-decoration: underline;
}

.bm-gallery-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
    z-index: 10;
}

.bm-gallery-item-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 10px;
    background-color: rgba(0,0,0,0.5);
}

/*
.bm-gallery:hover .bm-gallery-item-title {

}
*/

.bm-gallery .prev,
.bm-gallery .next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    color: white;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    z-index: 100;
}

.bm-gallery:hover .prev,
.bm-gallery:hover .next {
    opacity: 1;
}


.bm-gallery .prev {
    left: 0;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.3900910706079307) 0%, rgba(255,255,255,0) 100%);
}
.bm-gallery .next {
    right: 0;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0.3900910706079307) 0%, rgba(255,255,255,0) 100%);
}

.bm-gallery .prev i,
.bm-gallery .next i {
    position: absolute;
    font-size: 30px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%
}


.bg-light {
    background-color: #e2e2e2;
}

.my-1 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-1 {
    margin-top: 15px
}

.ml-1 {
    margin-left: 15px;
}

.mb-1 {
    margin-bottom: 15px
}

.mt-2 {
    margin-top: 30px
}
.mb-2 {
    margin-bottom: 30px
}

.mt-3 {
    margin-top: 60px
}

.mb-3 {
    margin-bottom: 60px
}

.block-item {
    cursor: pointer;
    border: 1px solid transparent;
    margin-bottom: 15px
}

.block-item:hover {
    border: 1px solid rgba(0,0,0,0.1)
}
.block-item.active {
    border: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.01)
}

.story-editor #title,
.story-editor #subtitle,
.story-editor #story_name,
.story-editor #published_at,
.story-editor #popular,
.story-editor #published_at,
.story-editor #minutes {
    cursor: pointer;
    border: 1px solid transparent;
}
.story-editor #title:hover,
.story-editor #subtitle:hover,
.story-editor #story_name:hover,
.story-editor #published_at:hover,
.story-editor #popular:hover,
.story-editor #published_at:hover,
.story-editor #minutes:hover {
    border: 1px solid rgba(0,0,0,0.1);
}

.story-editor .stories-double-paragraph {
    display: flex;
    justify-content: space-between;
}

.story-content-v2 .stories-double-paragraph {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
}

.story-content-v2 .stories-double-paragraph > * {
    margin: 0 15px;
}

.story-content-v2 .stories-double-paragraph .paragraph_item {
    flex: 0 1 auto;
}

.story-content-v2 .stories-double-paragraph .block_divider {
    width: 3px;
    background: #9a9a9a;
}

.thumbnail-bg {
    width: 100%;
    padding-bottom: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.03);
    border-radius: 3px;

}

.shadow {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.file-item {
    cursor: pointer
}

.d-none {
    display: none !important;
}

.file-item {
    position: relative;
    border: 1px solid transparent;
}

.file-item.selected {
    border: 1px solid #006eff;
}

.file-item.selected .delete-file {
    display: none
}

.delete-file {
    position: absolute;
    top: 0;
    right: 0px;
    background-color: red;
    cursor: pointer;
    padding: 0 3px;
    color: white;
    border-radius: 3px;
}

.filelist-item {
    padding-top: 5px;
    padding-bottom: 5px;
}
.filelist-item:hover {
    background-color: rgba(0,0,0,0.05)
}
.story-editor-btns-bottom {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 1000;
}

.story-block {

    margin: 50px 0px 50px 0px;
}

.story-block audio {
    width: 100%;
}

.story-block video {
    width: 100%;
}

p.italic {
    font-style: italic;
}

p.bold {
    font-weight: bold;
}

.story-media-title {
    font-size: 13px;
    text-align: center;
    padding-top: 5px
}

.story-content-v2 p, .story-editor p {
    font-size: 14px;
}

#file_browser .pager {
    margin-top: 0;
}

.item-page-thumbnails {
    margin: 0 -5px;
}

.thumbnail-item {
    padding: 0 5px;
    width: 25%;
    float: left;
    margin-bottom: 10px;
}

.item-page-thumbnails a {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    border-radius: 3px;
    width: 100%;
    padding-bottom: 100%;
    display: block;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}

/*
.szcenika-home {
    margin-top: -50px
}
*/

.szcenika-home a .panel .title {
    color: black !important;
}


.szcenika-home a {
    border-radius: 5px;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.15);
}

.szcenika-home .caption {
    opacity: 1 !important;
    display: block !important;
    background-color: #26A69A !important;
    color: white !important;
    font-weight: bold !important;
    left: 5px !important;
    bottom: 5px !important;
    right: unset !important;
    border-radius: 3px;
}

.szcenika-home .photo-item-overlay-bottom {
    display: block;
}

.m3-open-bg {
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(34,34,34,1) 100%);
    background-attachment: fixed;
}

.m3-open-player-container {
    padding-bottom: 56.25%;
    background-color: black;
    position: relative;
}

.m3-open-player-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: white;
    z-index: 200;
}

.form-control-invert,
.form-control-invert[readonly] {
    background-color: transparent;
    border: 1px solid white;
    color: white;
}


.radio-player-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-color: rgba(255,255,255,0.1);
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}


.radio-pg {
    position: absolute;
    top: 15px;
    left: 15px;
    border-radius: 50px;
    height: 35px;
    width: 35px;
}

.radio-pg-6 {
    border: 3px solid rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}
.radio-pg-12 {
    border: 3px solid rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}
.radio-pg-16 {
    border: 3px solid rgb(255, 193, 10);
    color: rgb(255, 193, 10)
}

.radio-pg span {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 14px;
    font-weight: bold;
}

.open-embed .radio-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 0;
    background-color: rgba(255,255,255,0.1);
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.radio-player-container .open-radio-player {
    position: absolute;
    bottom: 5px;
    left: 0px;
    right: 0px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
}

.radio-player-bubbles {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
}

.radio-player-bubbles .bubble {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: rgba(255,255,255,0.15);
    z-index: -1;
    border-radius: 50%;
    width: 0;
    padding-bottom: 0;
}

.radio-player-container.playing .bubble:nth-child(1) {
    animation: bubbles 3s infinite;
    animation-delay: 0;
}
.radio-player-container.playing .bubble:nth-child(3) {
    animation: bubbles 3s infinite;
    animation-delay: 0.5s;
}
.radio-player-container.playing .bubble:nth-child(3) {
    animation: bubbles 3s infinite;
    animation-delay: 1s;
}
.radio-player-container.playing .bubble:nth-child(4) {
    animation: bubbles 3s infinite;
    animation-delay: 1.5s;
}
.radio-player-container.playing .bubble:nth-child(5) {
    animation: bubbles 3s infinite;
    animation-delay: 2s;
}

@keyframes bubbles {
    0% {
        width: 0;
        padding-bottom: 0;
        opacity: 1;
    }
    100% {
        width: 100%;
        padding-bottom: 100%;
        opacity: 0;
    }
}

.radio-player-image {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 190px;
    padding-bottom: 190px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.open-radio-jwplayer {
    background-color: rgba(0,0,0,0.3);
    height: 40px;
}


.player-intro {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /*background-color: rgba(255,255,255,0.1);*/
}

.player-intro video {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
}

.player-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50;
}

.m3-open-player-container #jwplayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}



.player-intro .play {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    z-index: 150;
    cursor: pointer
}

.collection-highlights {
    position: relative;
    padding-bottom: 56.25%;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(255,255,255,0.1)
}

.collection-highlights.radio {
    padding-bottom: 100%;
}

.collection-highlight-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
}

.collection-highlight-item h4 {
    position: absolute;
    bottom: 15px;
    left: 15px;
    margin: 0;
    padding: 0;
    color: white;
    font-weight: bold;
    z-index: 100;
}

.collection-highlight-item h4 > .small {
    color: white !important;
    font-size: 12px;
    margin-top: 5px
}

.collection-highlight-item .loader {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0;
    background-color: white;
    z-index: 100;
}

.collection-highlight-item.active .loader {
    animation: line 5s linear
}

@keyframes line {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.open-embed {
    position: relative;
    height: 100vh;
    background-color: black;
}

.open-embed-player-container {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.open-embed-player {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}

.open-embed-player .player-intro video {
    height: 100%;
    background-color: black;
}


.open-embed-player  #embed_player {
    width: 100%;
    height: 100%;
    position: relative;
}

#embed_player #jwplayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
}

.open-embed-title {
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    right: 0;
    margin: 15px 60% 15px 15px;
    z-index: 1;
    opacity: 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.6225840678068102) 0%, rgba(34,34,34,0) 100%);
}

@media (max-width: 550px) {
    .open-embed-title h4 {
        font-size: 18px !important;
    }
    .open-embed-title-details {
        display: none;
    }
}

.open-embed-player-container:hover .open-embed-title {
    opacity: 1;
}

.open-embed:hover .open-embed-title {
    opacity: 1;
}


.open-embed-title h4 {
    font-weight: bold;
    font-size: 20px;
}

.open-embed-title a {
    font-weight: bold;
    color: white;
    text-decoration: underline;
}

.show-rank {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    padding: 4px 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 10;
    color: black;
    background: linear-gradient(-45deg, #fcff9e 0%, #c67700 100%);
    border-bottom-right-radius: 10px;
}

.clickable {
    cursor: pointer !important;
}

.clickable:hover {
    opacity: 0.7;
}

.btn-dark {
    background-color: rgba(255,255,255,0.1) !important;
    border: none !important;
    color: rgba(255,255,255,0.8) !important;
}

.btn-dark:hover {
    background-color: rgba(255,255,255,0.2) !important;
    color: rgba(255,255,255,1) !important;
}


.category-panel {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 100%;
    position: relative;
}

.category-panel .title {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-weight: bold;
    background-color: white;
    padding: 10px 30px;
    border-radius: 40px
}

.category-panel .panel-body {
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

#impaired {
    min-width: 15px;
    width: 4%;
    height: auto;
}

#impairedImg {
    width: 100%;
    height: auto;
}
.navbar-right {
    display: flex;
    align-items: center;
}

.select2-dropdown {
    z-index: 10002 !important;
}

#creatorDescriptionDiv {
    display: flex;
    flex-wrap: wrap;
}
#creatorDescriptionDiv > span {
    margin-top: 2%;
}

.player-icon {
    opacity: 0.5;
    width: 4%;
}

@media (max-width:600px) {
    .player-icon {
        width: 10%;
    }
}

.player-icons-container a:hover {
    text-decoration: none;
}

.player-icons-container a:active {
    text-decoration: none;
}

.player-icons-container a:visited {
    text-decoration: none;
}

.player-icons-container a:focus {
    text-decoration: none;
}

.player-icons-container .invert-m3 {
    filter: invert(1);
}

.stories-rows {
    margin: auto;
}

.stories-container {
    top: 0;
    margin-top: 50px;
}

.text-line-through {
    text-decoration: line-through;
}

a:focus {
    text-decoration: none;
    color: black;
}

.corporate-view-btn {
    background-color: #e2e2e2;
}

.quiz-item {
    position: relative;
    border:1px solid black;
    padding: 1.8rem;
    margin: 0.5rem;
    background: white;
}

.delete-item {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

select {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.quiz-empty {
    display: none;
}

.quiz-column {
    background: #fcf8e3;
}

.open-answers {
    position: relative;
    top: 3rem;
    right: 2rem;
}

.program-guide-thumbnail {
    position: relative;
    /*height: 100%;*/
    width: 100%;
    padding-top: 56.25%;
    /*aspect-ratio: 16 / 9;*/
    background-size: cover;
    border-radius: 1rem;
}

.program-guide-thumbnail .play-btn {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: rgba(255,255,255,0.7);
    transition: all 0.2s ease-in-out;
}

.program-guide-thumbnail:hover {
    cursor: pointer;
}

.program-guide-body .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
}

.icons div, .icons i, .icon .guide-icon {
    padding: 0.5rem;
}

.program-guide2 .guide-row {
    margin-right: 1rem;
}

.program-guide2 {
    color: rgb(218, 218, 218);
    position: relative;
    height: 991px;
}

.program-guide2 h4 {
    font-weight: bold;
}

.thumbnail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.show-description {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.icon-container {
    margin-top: 0px;
}

.program-guide-body-container {
    padding-left: 0;
}

.pg-title-container {
    padding-right: 0;
}

.icon-container > * {
    margin-right: 1rem;
}

.live-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.more-shows-link {
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}

.more-shows-link a {
    color: white;
}

.more-shows-link:hover {
    opacity: 2;
    transition: opacity 0.2s ease-in-out;
}

.document_title_center {
    text-align: center;
    font-weight: bold;
    padding: 1rem;
}

.month_select {
    margin: 0;
}

@media (min-width: 1200px) {
    .hero-container {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

.year-label {
    transform: translateY(-4px);
}

@media (min-width: 992px) {
    .active-container {
        display: flex;
    }

    .hero-text {
        flex: 1 0 auto;
    }

    .program-guide2 {
        position: absolute;
        top: 0;
        right: 0;
        height: 98%;
        padding: 0 8px 0 8px;
    }

    .more-shows {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

.photo-desktop-content {
    display: block;
}

.photo-phone-content {
    display: none;
}

.photo-download-info {
    padding: 15px;
    text-align: center;
}

.quota-value {
    padding: 3px 20px;
    font-size: 1.3rem;
    background-color: #dae2dcd9;
    color: #497252d9;
}

@media (max-width: 768px) {
    .photo-desktop-content {
        display: none;
    }

    .photo-phone-content {
        display: block;
        padding: 0 15px;
    }

    .quota-value {
        background-color: #fff;
    }
}
