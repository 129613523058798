.news-dropdown {
    position: relative;
    display: inline-block;
}

.news-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 400;
}

.news-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.news-dropdown-content a:hover {
    background-color: #ddd;
}

.news-dropdown:hover .news-dropdown-content {
    display: block;
}